import React from 'react'
import Empower from './Empower'
import Story from './Story'
import Team from './Team'

function Main() {
  return (
    
    <div className= ' sm:mx-5 pt-5 mx-10 '>
    <div className='sm:fixed sm:px-5 sm:top-[6%]  sm:mt-12 sm:left-0 '>
    <div className= ' sm:flex ' >
    <div>
        <a href='#intro' className=' font-[600] block text-[16px] mb-3 text-[#160404]'>Intro</a>
        <a href='#story' className=' font-[600] block text-[16px] mb-3 text-[#160404]'>Our story</a>
        <a href='#team' className=' font-[600] block text-[16px] mb-3 text-[#160404]'>The team</a>
    </div>
    </div>
    </div>
    <div className=' sm:grid sm:grid-cols-6  sm:gap-4  md:mx-5 sm:overflow-y-auto'>
        <div className= 'hidden col-span-1  sm:block'></div>
        <div className= ' col-span-5  sm:z-30'>
            <Empower/>
            <Story/>
            <Team/>
        </div>
    </div>
    </div>
  )
}

export default Main