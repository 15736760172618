import React from 'react'
import Progress from '../Dashboard/Progress'
import Work from '../../images/work.svg'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Button from '../SelectValue/Button';
import { Link } from 'react-router-dom';
function ProjectCom({value, amount, backed, name, work, video, linkA, header, cont, total, raised, location, count, co, saved, link}) {
  return (
    <div className=''>
    <div className=' grid sm:grid-cols-2   border-gray-50'>
    <div className=''>
        <div>
        <iframe class="w-full rounded-t-[18px] sm:rounded-t-[0px]  sm:rounded-l-[18px] h-[90vh]    aspect-video md:aspect-square ..." title='Client Video' src={`https://www.youtube.com/embed/${video}`} allowFullScreen></iframe>
        </div>
    </div>
    <div className=' rounded-b-[18px] sm:rounded-b-[0px] p-4  sm:col-span-2 sm:rounded-r-[18px] md:col-span-1 bg-[#E8F2F2] flex flex-col  justify-center'>
                    <div className='px-4 pt-7 sm:pt-0 sm:px-10'>
                        <h1 className=' text-[28px] mb-2 font-bold text-header-text capitalize'>{name}</h1>
                        <p className=' text-[12px] mb-5 font-[400] text-[#212020]'> <img src={Work} className=' inline-block' alt='work'/> {work} </p>
                        <h1 className=' mb-5 text-header-text text-[20px]  font-bold'>{header}</h1>
                        <p className=' text-[16px] font-normal text-[#444444] '>{cont} </p>
                        <div className=' mt-5'>
                            <div className='mb-3 flex justify-between'>
                                <p className=' text-header-text font-bold text-[12px]'>${amount} raised</p>
                                <p className=' text-header-text font-bold text-[12px]'>${total}</p>
                            </div>
                            <Progress
                                value={value}
                            />
                            <div className='mb-3 mt-2 flex justify-between'>
                                <p className=' text-sub-text font-normal text-[12px]'>{backed} backers</p>
                                <p className=' text-sub-text font-normal text-[12px]'>{count} months to go</p>
                            </div>
                        </div>
                        <div className=' my-6 flex gap-4'>
                                <p className=' text-[14px] font-normal text-[#6C6868]'><span className=' font-bold'>${raised}</span> {name} raised herself</p>
                            <Link to={linkA}><p className=' text-[14px] font-[700] underline text-[#202D2D]'>Match amount</p></Link>
                        </div>
                        <div className=' my-6'>
                            <p className=' font-normal text-[14px] text-[#696767] mb-3'>Expected CO<sub>2</sub> reduction - <span className='  font-bold'>{co} of Co<sub>2</sub> per Year</span></p>
                            <p className=' font-normal text-[14px] text-[#696767] mb-3'>Expected energy cost saving - <span className='  font-bold'>{saved}</span></p>
                        </div>
                        <div className=' my-6 flex justify-between'>
                        <div className=' flex justify-end flex-col'>
                        <p className=' text-sub-text font-[700] text-[10px]'><FmdGoodIcon/>{location}</p>
                        </div>
                           
                            <div>
                            <Link to={link}>
                                <Button
                                    name='Give'
                                />
                                </Link>
                            </div>
                        </div>
                    </div>
    </div>
    </div>
    </div>
  )
}

export default ProjectCom