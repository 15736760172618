import React, { useState} from 'react'
import AddsButtion from '../SelectValue/AddButtion'
import Textarea from '../SelectValue/Textarea'
import TextBox from '../SelectValue/TextBox'
import { addDoc, collection, } from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../firebase-config'
function Contactus() {
    const [email, setEmail] = useState('')
    const [sub, setSub] = useState('')
    const [mess, setMess] = useState('')
    const [name, setName] = useState('')
    const sendmail = (e) =>{
      e.preventDefault()
      toast.success('Mail sent successfully!!')
      addDoc(collection(db, "mails"),{
        
          to: 'info@boostytech.com',
          message: {
            subject: 'Seeking for help',
            html: `<div> <p> Hello, ${name} with email of ${email} sent a  message with a subject of - ${sub}. Below is the message.<br></br> ${mess} </p> </div>` ,
          },
        }
      )
      setName('')
      setEmail('')
      setMess('')
      setSub('')
    }
  return (
    <div className='   px-7  md:px-16 mb-[96px] py-[96px] rounded-l-2xl bg-[#F3F8F8] ml-20'>
    <ToastContainer />
        <div className=' wow animate__animated animate__fadeInLeft    w-full grid md:grid-cols-3 '>
        <div className=' mx-auto lg:w-[70%]  md:col-span-2'>
        <h1 className=' text-[18px] md:text-[25px] font-[700] text-header-text mb-3'>Need help funding your renewable energy need?</h1>
        <p className=' mb-5 text-sub-text text-[14px] md:text-[16px] font-[600]'>Are you living in an energy-vulnerable community in Africa, and looking to transition to renewable energy?

                <br></br>
                <br></br>
            Send us a message.</p>
            <div className=' w-full'>
            <form onSubmit={sendmail}>
               
               <div className=' my-3'>
               <TextBox
                   place='Your name'
                   type='text'
                   name="user_name"
                   onChange={setName}
                   value={name}
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your email'
                   type='email'
                   onChange={setEmail}
                   value={email}
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your subject'
                   type='text'
                   value={sub}
                   onChange={setSub}
               />
               </div>
               <div className=' min-w-fit relative my-5'>
                   <Textarea 
                       place='Your message'
                       name='message'
                       onChange={setMess}
                       value={mess}
                   />
                   <div className=' absolute bottom-3 right-2 '>
                   <AddsButtion
                       name='Send'
                   />
               </div>
               </div>
               
           </form>
            </div>
            </div>
        </div>

    </div>
  )
}

export default Contactus