import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAAiQFJbJrgShYPcAsiTNwpnp1DBEhpukM",
  authDomain: "boosty-9f206.firebaseapp.com",
  projectId: "boosty-9f206",
  storageBucket: "boosty-9f206.appspot.com",
  messagingSenderId: "647796988773",
  appId: "1:647796988773:web:c0048c227ba3e1ecf59f8c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app)
export const auth = getAuth()
export const db = getFirestore(app);

