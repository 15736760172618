import React, { useEffect, useState } from 'react'
import GiveButton from '../SelectValue/GiveButton'
import TextBox from '../SelectValue/TextBox'
import Logo from '../../images/boostylogo.png'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
function CreditTopCom({img, head, sub}) {
    const [name, setName] = useState('')
    const [amount, setAmount] = useState(0)
    const [email, setEmail] = useState('')
    const [pNum, setPNum] = useState('')
        const [color, setColor] = useState(false)
        useEffect(() =>{
            if(email.length > 1 && name.length > 3 && pNum.length > 7 ){
                setColor(true)
            }else{
                setColor(false)
            }

        },[email, name, pNum])
        const increament = () =>{
                setAmount(amount+ 1)
        }
        const decreament = () =>{
            if(amount >= 1){
                setAmount(amount - 1)
            }
            else{
                setAmount(amount)
            }
           
    }
    const config = {
        public_key: 'FLWPUBK-1c103a654a09dc46282fbe1d4e8d4d28-X',
        tx_ref: Date.now(),
        amount: amount ,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: email,
          phonenumber: pNum,
          name: name,
        },
        customizations: {
          title: 'Donation for Afolakemi Kehinde',
          description: 'Payment for rice processing',
          logo: {Logo},
        },
      };
    
      const handleFlutterPayment = useFlutterwave(config);
    const handleSubmit = (e) =>{
        e.preventDefault()
        handleFlutterPayment({
            callback: (response) => {
            console.log(response);
             closePaymentModal() // this will close the modal programmatically
            
              },
             onClose: () => {
                  
             },
             });
    }
  return (
    <div>
    <form onSubmit={handleSubmit}>
     <div className=' rounded-[18px] mb-8 border grid md:grid-cols-2 gap-6 px-6 py-8 border-[#C4C4C4]'>
        <div>
            <h1 className=' md:text-[30px] mb-4 font-bold text-header-text'>Back this project</h1>
            <div className=' flex gap-5'>
                <div>
                    <img src={img} alt='img'/>
                </div>
                <div>
                    <h1 className=' md:text-[18px] mb-2 font-bold text-header-text'>{head}</h1>
                    <p className='text-[12px] font-normal text-sub-text'>{sub}</p>
                </div>
            </div>
        </div>
        <div className=' flex flex-col justify-center'>
            <div className=' mx-auto'>
                <h1 className=' md:text-[18px] mb-4 font-bold   text-header-text'>Pledge amount</h1>
                <div className=' flex gap-6'>
                <div className='mt-3 flex '>
                    <div  onClick={decreament} className=' cursor-pointer p-3 font-bold border-r border-r-[#ACABAB] rounded-tl-[18px] rounded-bl-[18px] bg-[#E8F2F2]'>
                        -
                    </div>
                    <div onClick={increament} className=' cursor-pointer p-3 font-bold  rounded-tr-[18px] rounded-br-[18px] bg-[#E8F2F2]'>
                        +
                    </div>
                </div>
                <div>
                    <input
                     className=' p-3 bg-[#E8F2F2] mt-3 w-24 rounded-[18px]'
                     type='number'
                     required
                     value={amount}
                     min="0"
                     oninput="validity.valid||(value='');"
                     onChange={(e) => setAmount(e.target.value)}
                    />
                </div>
                </div>
               
            </div>
        </div>
    </div>
    <div className=' rounded-[18px] mb-8 border grid md:grid-cols-2 gap-6 px-6 py-8 border-[#C4C4C4]'>
        <div>
            <h1 className=' md:text-[30px] mb-4 font-bold text-header-text'>Enter your details</h1>
            <div className='my-4'>
                <TextBox
                    label='Fullname'
                    value={name}
                    onChange={setName}
                />
            </div>
            <div className='my-4'>
                <TextBox
                    label='Email address'
                    value={email}
                    onChange={setEmail}
                />
            </div>
            <div className='my-4'>
                <TextBox
                    label='Phone number'
                    value={pNum}
                    onChange={setPNum}
                />
            </div>
            <div className='my-4'>
                    {/* <Link to='/success'> */}
                    <GiveButton
                        name='Give'
                        bool={color}
                    />
                   
            </div>
        </div>
    </div>
    </form>
    </div>
   
  )
}

export default CreditTopCom