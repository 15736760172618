import React from 'react'
import { useUserAuth } from '../context/UserAuthContext'
import First from '../../images/avata.jpeg'
import Section from './Section'
function EditProfile() {
  const {user} = useUserAuth()
  return (
    <div className=' flex justify-center'>
      <div>
      <div className=' border border-gray-200 p-3'>
      <img src={ user.photoURL === null ? First :  `${user.photoURL}`} width='105' height='105' alt='First' className='   mx-auto'/>
      </div>
       <div className=' my-4'>
        <Section/>
       </div>
      </div>
    </div>
  )
}

export default EditProfile