import React from 'react'
import Content from '../../Components/Campaign/Content'

function Campaign() {
  return (
    <div>
      <Content/>
    </div>
  )
}

export default Campaign