import React from 'react'
import Imparting from '../../images/ii.png'
function Impart() {
  return (
    <div className=' py-[96px] px-7 md:ml-10 my-10 rounded-l-2xl bg-[#F3F8F8] grid gap-4 md:grid-cols-2'>
        <div>
            <img src={Imparting} className='wow animate__animated animate__zoomIn mx-auto' alt='Impart'/>
        </div>
        <div className=' wow animate__animated animate__fadeInLeft sm:flex sm:justify-center sm:flex-col lg:w-[70%]'>
            <div>
            <h1 className=' text-[18px] md:text-[25px] font-[700] text-header-text mb-3'>We are all about real impact</h1>
            <p className=' text-sub-text text-[14px] md:text-[16px] font-[600]'>Donors will see the impact of their contributions through updates on funding progress and videos of renewable energy system installations. 
             <br></br>
             <br></br>
               Additionally, Boosty will organize online meetups where donors can hear directly from the beneficiaries.</p>
            </div>
           
        </div>
    </div>
  )
}

export default Impart