import React from 'react'
import {  NavLink } from 'react-router-dom'
import Progress from './Progress'

function DonationCom({img, name, sub, per,price, backers, value, link, weeks}) {
  return (
    <NavLink
      className={({isActive}) =>{
                            return( ' block p-3 ' + (
                              !isActive ? ' block ' : ' bg-[#EFF6F6] rounded-[18px]   block'
                            ))
                        }}  
     to={link}>
      <div className=' mb-4'>
         <div className=' p-2 pt-4 flex justify-start gap-2  mb-2 pb-4  '>
        <div>
        <img src={img} width='50' height='50' alt='First' className='  mx-auto'/>
        </div>
      
        <div>
          <p className=' text-[16px] font-bold'>{name}</p>
          <p className=' text-[14px] font-semibold text-[#878787]'>{sub}</p>
        </div>
        <p></p>
     </div>
     <Progress value={value}/>
     <div className=' flex mt-4 gap-4'>
        <div>
            <p className=' text-[#B99B4F] font-[800]  text-[12px]'>{per}</p>
            <p className=' text-[#B99B4F] font-[800]  text-[12px]'>funded</p>
        </div>
        <div>
           <p className=' text-[#6C6868] font-[700]  text-[12px]'>{price}</p>
            <p className=' text-[#6C6868] font-[400]  text-[12px]'>you donated</p>
        </div>
        <div>
            <p className=' text-[#6C6868] font-[700]  text-[12px]'>{backers}</p>
            <p className=' text-[#6C6868] font-[400]  text-[12px]'>backers</p>
        </div>
        <div>
            <p className=' text-[#6C6868] font-[700]  text-[12px]'>{weeks}</p>
            <p className=' text-[#6C6868] font-[400]  text-[12px]'>weeks to go</p>
        </div>
        <div></div>
     </div>
    </div>
    </NavLink>
    
  )
}

export default DonationCom