import React from 'react'
import EditProfile from '../../Components/Settings/EditProfile'
import Logo from '../../images/boostylogo.png'
import { Link } from 'react-router-dom'
function Settings() {
  return (
    <div>
    <div className='mb-4 '>
    <Link to='/'>
      <img src={Logo} className=' mx-auto'  alt='Logo' />
      </Link>
    </div>
        <EditProfile/>
    </div>
  )
}

export default Settings