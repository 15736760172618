import React from 'react'

function ButtonImage({name, onClick, color, imgg}) {
  return (
    <button 
    className= {` ${color} w-full  px-[18px] border border-gray-300 py-[10px] font-[700]  rounded-[99px] `}
    onClick={onClick}
   > <img src={imgg} width='25' className=' inline-block' alt='imgg'/>  {name}</button>   
  )
}

export default ButtonImage