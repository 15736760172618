import React, { useEffect, useState } from 'react'
import Back from '../Back'
import Pi from '../../images/c2.png'
import { PayPalButton } from "react-paypal-button-v2";
import Success from './Success';
import { createRecord } from '../context/DbContext';
import { useUserAuth } from '../context/UserAuthContext';
import Logo from '../../images/boostylogo.png'
import {addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import { Link, useParams } from 'react-router-dom';
function PaypalPayment1() {
  const{amount} = useParams()
  let amountInt = parseInt(amount)
  const [amountt, setAmount] = useState(amountInt)
  const [open, setOpen] = useState(false)
  const { user } = useUserAuth();
  const uuid = user.uid
  const [donationValues, setDonationValue] = useState(0)
  const [prevDonation, setPrevDonation] = useState(0)
  const [aPrevDonation, setaPrevDonation] = useState(0)
  const [backed, setBacked] = useState(0)
  const [copied, setCopied] = useState(false);
  const textToCopy = `I just donated to Abiodun Adewale renewable energy project on Boosty. Go to boosty.com to support a campaign `;
  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };
  const percentage = 2.99;
  // Calculate the result
  const result = (percentage / 100) * amountt;
    const increament = () =>{
        setAmount(amountt+ 10)
}
const handleOpen = () => setOpen(true)
const handleClose = () => setOpen(false)
const decreament = () =>{
    if(amountt >= 1){
        setAmount(amountt - 10)
    }
    else{
        setAmount(amountt)
    }
  }
const handleInputChange = (event) => {
  const inputValue = parseInt(event.target.value);
  if (!isNaN(inputValue)) {
    setAmount(inputValue);
  }else{
    setAmount(0)
  }
};
const donationValue = async () =>{
  const data = {
    donation: amountt +  parseInt(aPrevDonation),
  };
  const datas = {
    donation: parseInt(donationValues) + parseInt(amountt),
  };
  const dataa = {
    donation: parseInt(amountt) +  parseInt(prevDonation),
  };
  const datass = {
    backed: parseInt(backed) + 1,
  };
const response=   await createRecord(uuid,'donations',dataa)
const responses = await createRecord('aaabosty','abiodunDonation',datas)
const responsess = await createRecord('aaabacked','abiodunDonation',datass)
const responsesss=   await createRecord(uuid,'adonations',data)
console.log(response)
console.log(responses)
console.log(responsess)
console.log(responsesss)
}
const unSub = async () =>{
  const docRef = doc(db, "abiodunDonation", 'aaabosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setDonationValue(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub1 = async () =>{
  const docRef = doc(db, "abiodunDonation", 'aaabacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub2 = async () =>{
  const docRef = doc(db, 'donations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

const unSub3 = async () =>{
  const docRef = doc(db,  'adonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setaPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

useEffect(() =>{
  unSub()
  unSub1()
  unSub2()
  unSub3()
}, [])
const sendmail = () =>{
  addDoc(collection(db, "mails"),{
    
      to: ['lightcrowntech@gmail.com'],
      message: {
        subject: 'Donation confirmation message',
        html: `<div> <p>Hi Abiodun, <br><br> Your renewable energy project has received a donation of $${amountt}. <br><br> You have $${parseInt(amountt) +  parseInt(aPrevDonation)} donated so far</p> </div>` ,
      },
    }
  )

}
const sendmail1 = () =>{
  addDoc(collection(db, "mails"),{
    
      to: ['info@boostytech.com','aithelight@gmail.com'],
      message: {
        subject: 'Donation confirmation message',
        html: `<div> <p>Hi Boosty, <br><br> Your Abiodun renewable energy project has received a donation of $${amountt}. <br><br> You have $${parseInt(amountt) +  parseInt(aPrevDonation)} donated so far</p> </div>` ,
      },
    }
  )

}
let semiFinal = Math.ceil(amountt + result)
let finalValue = (semiFinal).toString()
  return (
    <div className=' '>
     <Success
     onClick={handleCopyClick}
      copy={copied}
      open={open}
      handleClose={handleClose}
      dimage={Pi}
      email={'lightcrowntech@gmail.com'}
      link='/campaign2'
    />
     <div className=' mb-4'>
    <Link to='/'>
      <img src={Logo} className=' mx-auto'  alt='Logo' />
      </Link>
    </div>
         <div className=' mx-auto  grid grid-cols-6'>
    <div className=' flex flex-col px-10  pb-5 md:pb-0 justify-items-center md:mt-32'>
        <Back/>
    </div>
      
         <div className=' col-span-4'>
         <div className=' rounded-[18px] mb-8 border grid lg:grid-cols-2 gap-6 px-6 py-8 border-[#C4C4C4]'>
        <div>
            <h1 onClick={handleOpen} className=' md:text-[30px] mb-4 font-bold text-header-text'>Back this project</h1>
            <div className=' flex gap-5'>
                <div>
                    <img src={Pi} alt='img'/>
                </div>
                <div>
                    <h1 className=' md:text-[18px] mb-2 font-bold text-header-text'>Give my Printing Company a boost</h1>
                    <p className='text-[12px] font-normal text-sub-text'>I will use a renewable energy system to power my SMT heat soldering machines, soldering irons, office lighting, and monitors. Your donation counts!</p>
                </div>
            </div>
        </div>
        <div className=' flex flex-col justify-center'>
            <div className=' mx-auto'>
                <h1 className=' md:text-[18px] mb-4 font-bold   text-header-text'>Donation amount</h1>
                <div className=' flex gap-6'>
                {/* <div className='mt-3 flex '>
                    <div  onClick={decreament} className=' cursor-pointer p-3 font-bold border-r border-r-[#ACABAB] rounded-tl-[18px] rounded-bl-[18px] bg-[#E8F2F2]'>
                        -
                    </div>
                    <div onClick={increament} className=' cursor-pointer p-3 font-bold  rounded-tr-[18px] rounded-br-[18px] bg-[#E8F2F2]'>
                        +
                    </div>
                </div> */}
                <div className=' flex '>
              
                <div className='dollar-input'>
                <input
                     className=' mt-3 p-3 appearance-none bg-[#E8F2F2] pl-6 border rounded-l-[12px] border-[#C3C5C5]  w-full outline-0 '
                     type='text'
                     required
                     value={amountt}
                     onChange={handleInputChange}
                    />
                </div>
                      <span className="mt-3 p-3 bg-[#E8F2F2] border border-[#C3C5C5] rounded-r-[12px]">USD</span>
                </div>
                </div>
               
            </div>
        </div>
    </div>
    <div className=' block mb-3'>
    <p className='md:text-[18px] mb-4 font-bold   text-header-text'>Transaction fee: ${Math.ceil(result)} USD will be added to your donation amount </p>
    </div>
    <div className=' rounded-[18px] mb-8 border flex justify-between gap-6 px-6 py-8 border-[#C4C4C4]'>
    <div>
        <p>Estimated total: ${Math.ceil(result + amountt)}</p>
    </div>
        <PayPalButton
        amount={finalValue}
        
        // shippingPreference="Abiodun campaign donation" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          alert("Transaction completed by " + details.payer.name.given_name);
          console.log(details)
          console.log(data)
          setOpen(true)
          donationValue()
          sendmail()
          sendmail1()
          // OPTIONAL: Call your server to save the transaction
          return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({
              orderID: data.orderID
            })
          });
        }}
        options={{
        clientId: "Adw-f1G8am22ZeMGs-Ye6IOWtu9awIgXywx2GcGgAHO3n6VfTvQJTOvh8L7bXmZ6qShBsQsWlRn5DnqW",
        currency: "USD"
    }}
      />
      <div></div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default PaypalPayment1