import React from 'react'
import Work from '../../images/work.png'
function Working() {
  return (
    <div className='md:mx-0 sm:mx-7 px-7 py-[96px] grid md:grid-cols-2 gap-4' id='about'>
        <div className=' flex justify-center'>
            <div className=' wow animate__animated animate__fadeInLeft pb-5 sm:flex sm:justify-center sm:flex-col lg:w-1/2'>
            <div>
            <h1 className=' text-[18px] md:text-[25px] font-[700] text-header-text mb-3'>We are working for a sustainable future</h1>
            <p className=' text-sub-text text-[14px] md:text-[16px] font-[600]'>Boosty is dedicated to helping people in energy-vulnerable communities find donors for their sustainable energy needs, as the adoption of renewable energy remains costly for many in Africa.</p>
            </div>
            
            </div>
            
        </div>
        <div>
            <img src={Work} className='wow mx-auto md:mx-0 animate__animated animate__zoomIn ' alt='work'/>
        </div>
    </div>
  )
}

export default Working