import React from 'react'
import Logo from '../../images/boostylogo.png'
import link from '../../images/linnk.svg'
import { NavLink } from 'react-router-dom'
function Navbar() {
  return (
    <div className=' pt-5 pb-2 flex fixed z-50 bg-white w-full justify-between px-7'>
        <img src={Logo} alt='Logo'/>
        <div>
          <NavLink to='/'  className={({isActive}) =>{
                            return( '  p-3 mb-3 pl-6 ' + (
                              !isActive ? '  ' : ' bg-[#FDFDFE] font-[700] text-[#160404]'
                         ))
                        }}  >Home</NavLink>
          <NavLink to='/project'  className={({isActive}) =>{
                            return( '  p-3 mb-3 pl-6 ' + (
                              !isActive ? '  ' : ' bg-[#FDFDFE] font-[700] text-[#160404]'
                            ))
                        }}  >Projects</NavLink>
          <NavLink to='/aboutus' className={({isActive}) =>{
                            return( '  p-3 mb-3 pl-6 ' + (
                              !isActive ? '  ' : ' bg-[#FDFDFE] font-[700]  text-[#160404]'
                            ))
                        }}  >About us</NavLink>
          <NavLink to='/contacts'  className={({isActive}) =>{
                            return( '  p-3 mb-3 pl-6 ' + (
                              !isActive ? '  ' : ' bg-[#FDFDFE] font-[700] text-[#160404]'
                            ))
                        }}  >Contact us</NavLink>
        </div>
            <div className=' text-[#160404] flex flex-col'>
            <a href='https://www.linkedin.com/company/boosty-inc' target='_blank' rel='noreferrer'>
            <img src={link} alt='LinkedIn' className=''/>
            </a>
          
            </div>
      
    </div>
  )
}

export default Navbar