import React from 'react'
import { Link } from 'react-router-dom'

function OptionComponent({name, sub, img, link}) {
  return (
    <Link to={link}>
      <div className=' rounded-[18px] grid w-full mx-auto md:w-1/2 overflow-x-auto grid-cols-2 my-8 gap-3 border-[#B3B3B3] border hover:border-[#F3B921] hover:border-[3px] '> 
        <div className='p-4 md:p-8 bg-[#E8F2F2] '>
            <h1 className=' text-center text-header-text font-[700] text-[16px] md:text-[25px] my-3'>{name}</h1>
            <p className=' text-center text-sub-text font-[400] text-[12px] md:text-[16px]'>{sub}</p>
        </div>
        <div className='p-4 md:p-8'>
            <img src={img} alt='options'/>
        </div>
    </div>
    </Link>
   
  )
}

export default OptionComponent