import React, { useEffect,useRef , useState } from "react";

import Forward from '../../images/ba.svg';
import Back from '../../images/for.svg';
import ProjectCom from "../Project/ProjectCom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    
     <div></div>
   
  );
}

function SamplePrevArrow(props) {
  const { className,  onClick } = props;
  return (
    
    <div></div>

  );
}

const CustomArrows = () => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 200,
    autoplaySpeed: 9000,
    cssEase: "fade",
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  const [amount1, setAmount1] = useState(0)
  const [backed1,setBacked1] = useState(0)
  const [amount2, setAmount2] = useState(25.64)
    const [backed2,setBacked2] = useState(0)
    const [amount3, setAmount3] = useState(25.64)
  const [backed3,setBacked3] = useState(0)
  const [amount4, setAmount4] = useState(0)
  const [backed4,setBacked4] = useState(0)
  const[backed5, setBacked5] = useState(0)
   const[backed6, setBacked6] = useState(0)
   const [amount5, setAmount5] = useState(0)
   const [amount6, setAmount6] = useState(0)
  const unSub1 = async () =>{
      const docRef = doc(db, "kindeDonation", 'kkkbosty');
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().donation);
        setAmount1(docSnap.data().donation)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  
    const unSub2 = async () =>{
      const docRef = doc(db, "kindeDonation", 'kkkbacked');
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().backed);
        setBacked1(docSnap.data().backed)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    
    const unSub3 = async () =>{
        const docRef = doc(db, "abiodunDonation", 'aaabosty');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().donation);
          setAmount2(docSnap.data().donation)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    
      const unSub4 = async () =>{
        const docRef = doc(db, "abiodunDonation", 'aaabacked');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().backed);
          setBacked2(docSnap.data().backed)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
      const unSub5 = async () =>{
          const docRef = doc(db, "emmaDonation", 'eeebosty');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().donation);
            setAmount3(docSnap.data().donation)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      
        const unSub6 = async () =>{
          const docRef = doc(db, "emmaDonation", 'eeebacked');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().backed);
            setBacked3(docSnap.data().backed)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
        const unSub7 = async () =>{
          const docRef = doc(db, "joyDonation", 'jjjbosty');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().donation);
            setAmount4(docSnap.data().donation)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      
        const unSub8 = async () =>{
          const docRef = doc(db, "joyDonation", 'jjjbacked');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().backed);
            setBacked4(docSnap.data().backed)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
        const unSub9 = async () =>{
          const docRef = doc(db, "ArejeDonation", 'reebosty');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().donation);
            setAmount5(docSnap.data().donation)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      
        const unSub10 = async () =>{
          const docRef = doc(db, "ArejeDonation", 'reebacked');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().backed);
            setBacked5(docSnap.data().backed)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
        const unSub11 = async () =>{
          const docRef = doc(db, "bukolaDonation", 'buubosty');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().donation);
            setAmount6(docSnap.data().donation)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      
        const unSub12 = async () =>{
          const docRef = doc(db, "bukolaDonation", 'buubacked');
          const docSnap = await getDoc(docRef);
        
          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data().backed);
            setBacked6(docSnap.data().backed)
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      useEffect(() =>{
          unSub1()
          unSub2()
          unSub3()
          unSub4()
          unSub5()
          unSub6()
          unSub7()
          unSub8()
          unSub9()
          unSub10()
          unSub11()
          unSub12()
      }, [])
  const value1= (amount1/ 15941.25) * 100
  const value2 = (amount2/ 11019.81) * 100
  const value3 = (amount3/ 5201.83) * 100
  const value4 = (amount4/ 16729.23) * 100
  const value5 = (amount5/ 4747.50) * 100
  const value6 = (amount6/ 7561.03) * 100
  return (
    <div className="p-6 bg-[#F3F8F8] rounded-r-2xl relative pt-28 mr-32 px-[159px]">
      <h2 className="text-[25px] font-[700] text-[#2D2B2B] mb-2">Select a project to give to </h2>
      <div className=" w-full h-100">
      <Slider  ref={sliderRef} {...settings}>
       
      <div className=' mb-[96px]'>
        <ProjectCom
            value={value1}
            amount={amount1}
            backed={backed1}
            count={6}
            name='Afolakemi Kehinde'
            work='Training, planting, processing, packaging and marketing unpolished rice'
            video='Mjo5r7h1FKM'
            header='Give my rice processing company a boost'
            cont='I will use the renewable energy to power my machines: Grinding machine, rice de-stoner, blower, dryer, Sack sewing machine, package sealant. Your donations make a significant impact!'
            total='15,941.25'
            raised='32.10'
            location='Ogun, Nigeria'
            co='21,216 Kg '
            saved='$4573.8 per Year'
           link='/donate1/1'
           linkA='/donate1/32'
        />
        </div>
        <div className=' mb-[96px]'>

        <ProjectCom
            value={value2}
            amount={amount2}
            backed={backed2}
            name='Abiodun Adewale'
            work='Digital Printing & Framing'
            video='e8llCuq1qQU'
            header='Give my Printing Company a boost'
            cont=' I will use a renewable energy system to power my SMT heat soldering machines, soldering irons, office lighting, and monitors. Your donation counts!'
            total='11,019.81'
            raised='25.64'
            location='Lagos, Nigeria'
            link='/donate2/1'
            linkA='/donate1/26'
            co='6,520 Kg '
            saved='$2311.7 per Year'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value3}
            amount={amount3}
            backed={backed3}
            name='Emmanuel Oguntade '
            work='Electronics & Electrical Repairs and Sales'
            video='BELmh-LSFCw'
            header='Give my electronic repair shop a boost'
            cont=' I will use a renewable energy system to power my SMT heat soldering machines, soldering irons, office lighting, and monitors. Your donation counts!'
            total='5,201.83'
            raised='25.64'
            location='Oyo, Nigeria'
            link='/donate3/1'
            linkA='/donate1/26'
            co='12,916.8 Kg '
            saved='$4225.7 per Year'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value4}
            amount={amount4}
            backed={backed4}
            name='Joy  Kounasso'
            work='A textile Merchandising bespoke'
            video='BcHhg-vhcGY'
            header='Boost my textile merch  business.'
            cont='I will use the renewable energy system to power my Straight sewing machine, monogram machine, presser, plotter tacker, Buttonhole machine.Every dollar counts'
            total='6,729.23'
            raised='25.6'
            location='Lagos, Nigeria'
            link='/donate4/1'
            linkA='/donate1/26'
            co='14,352 Kg '
            saved='$4695.2 per Year'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value5}
            amount={amount5}
            backed={backed5}
            name='Tunbosun Areje '
            work='3D Interior Wall frame Designer'
            video='WlacAELKshg'
            header='Boost my wall frame design business '
            cont='I will use a renewable energy system to power my drilling machine, sandpaper machine, jigsaw, and television. Your donation counts!'
            total='4,747.50'
            raised='72.92'
            location='Ondo, Nigeria'
            link='/donate5/1'
            linkA='/donate1/73'
            co='7,893.6 Kg '
            saved='$2582.3 per Year'
        />
        </div>
        <div className=' mb-[120px]'>
        <ProjectCom
            value={value6}
            amount={amount6}
            backed={backed6}
            name='Bukola Adedeji'
            work=' Manufacturing and Supply of ready to wear.'
            video='kfha6b1f6Bw'
            header='Give my ready-to-wear fabric business a boost'
            cont=' I will use a renewable energy system to power my monogram 6 heads, presser, cutter, industrial machines, taping and buttonhole machine, and tacker machine. I am counting on you!'
            total='7,561.03'
            raised='320.51'
            location='Lagos, Nigeria'
            link='/donate6/1'
            linkA='/donate1/321'
            co='10,764 Kg '
            saved='$3521.4 per Year'
        />
        </div>
       
      </Slider>
      <div className=" absolute top-1/2 left-24">
        <button className="button" onClick={previous}>
        <img src={Back} alt="prev" />
        </button>
        </div>
        <div className=" absolute top-1/2 right-24">
        <button className="button" onClick={next}>
        <img   src={Forward} alt="forward" />
        </button>
      </div>
      </div>
     
    </div>
  );
};

export default CustomArrows;
