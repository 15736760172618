import React from 'react'
import Navbar from '../../Components/Home/Navbar'
import Options from '../../Components/Select/Options'

function Select() {
  return (
    <div>
        <Navbar/>
        <Options/>
    </div>
  )
}

export default Select