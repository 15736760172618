import React from 'react'
import CreditTopCom from './CreditTopCom'
import Pi from '../../images/pi.png'
import Back from '../Back'
function CreditCardPayment1() {
  return (
    <div className=' mx-auto mt-8 flex'>
    <div className=' flex flex-col px-5  justify-items-center md:mt-32'>
        <Back/>
    </div>
        <CreditTopCom
            img={Pi}
            head='Give my rice processing a boost'
            sub= 'It will improve my production and reduce the stress of waiting for electricity before we can print for the customers. It will also increase employment opportunity.'
        />
    </div>
  )
}

export default CreditCardPayment1