import React, { useEffect, useState } from 'react'
import Work from '../../images/work.svg'
import Progress from '../Dashboard/Progress'
import Cake from '../../images/cake.png'
import Edu from '../../images/edu.png'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import FundingRCom from './FundingRCom'
import Back from '../../images/fford.svg'
import Close from '../../images/bback.svg'
import Plug from '../../images/plug.svg'
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import Logo from '../../images/boostylogo.png'
// import R from '../../images/right.png'
// import D from '../../images/down.png'
// import T from '../../images/top.png'
import { Link } from 'react-router-dom'
import Button from '../SelectValue/Button'
function Content5() {

const [amount, setAmount] = useState(0)
const [backed,setBacked] = useState(0)
const unSub = async () =>{
    const docRef = doc(db, "ArejeDonation", 'reebosty');
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data().donation);
      setAmount(docSnap.data().donation)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const unSub1 = async () =>{
    const docRef = doc(db, "ArejeDonation", 'reebacked');
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data().backed);
      setBacked(docSnap.data().backed)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }
    
    useEffect(() =>{
        unSub()
        unSub1()
    }, [])
const value = (amount/  4747.50) * 100
  return (
    <div className=' relative'>
    <div>
    {/* <Progress
    value={scroll}
  /> */}
    </div>
  
    <div className=' flex mb-5 justify-between'>
          <div> 
          <p className=' text-[16px] font-[400]'>All projects</p>
          </div>
        <Link to='/'>
      <img src={Logo} width={70} height={70}  alt='Logo' />
      </Link>
          </div>
     
            <div className=' h-[80vh] sm:h-[75vh] rounded-[18px]  border  relative overflow-y-auto '>
    
        <div className='rounded-[18px] '>
            <div className=' grid    lg:grid-cols-2'>
                <div className=' col-span-2  md:col-span-1 '>
                <iframe class="w-full h-[50vh] sm:h-[75vh]    aspect-video md:aspect-square ..." title='Client Video' src="https://www.youtube.com/embed/WlacAELKshg" allowFullScreen></iframe>
                </div>
                <div className=' bg-[#E8F2F2] col-span-2 md:col-span-1  flex flex-col  justify-center'>
                    <div className='px-4 pt-7 sm:pt-0 sm:px-10'>
                        <h1 className=' text-[22px] mb-2 font-bold text-header-text capitalize'>Tunbosun Areje</h1>
                        <p className=' text-[12px] mb-5 font-[400] text-[#212020]'> <img src={Work} className=' inline-block' alt='work'/> 3D Interior Wall frame Designer </p>
                        <h1 className=' mb-5 text-header-text text-[18px]  font-bold'> Boost my wall frame design business </h1>
                        <p className=' text-[16px] font-normal text-[#444444] '> I I will use a renewable energy system to power my drilling machine, sandpaper machine, jigsaw, and television. Your donation counts! </p>
                        <div className=' mt-5'>
                            <div className='mb-3 flex justify-between'>
                                <p className=' text-header-text font-bold text-[12px]'>${amount} raised</p>
                                <p className=' text-header-text font-bold text-[12px]'>$ 4,747.50</p>
                            </div>
                            <Progress
                                value={value}
                            />
                            <div className='mb-3 mt-2 flex justify-between'>
                                <p className=' text-sub-text font-normal text-[12px]'>{backed} backers</p>
                                <p className=' text-sub-text font-normal text-[12px]'>6 months to go</p>
                            </div>
                        </div>
                        <div className=' my-6 flex gap-4'>
                                <p className=' text-[14px] font-normal text-[#6C6868]'><span className=' font-bold'>$72.92</span> Areje raised herself</p>
                                <Link to='/payment/paypal5/78'>    <p className=' text-[14px] font-bold underline text-[#6C6868]'>Match amount</p></Link>
                        </div>
                        <div className=' my-8'>
                            <p className=' font-normal text-[14px] text-[#696767] mb-3'>Expected CO<sub>2</sub> reduction - <span className='  font-bold'>7,893.6 Kg of Co<sub>2</sub> per Year</span></p>
                            <p className=' font-normal text-[14px] text-[#696767] mb-3'>Expected energy cost saving - <span className='  font-bold'>$2582.3 per Year</span></p>
                        </div>
                        <div className=' my-6'>
                            <p className=' text-sub-text'><FmdGoodIcon/> Ondo, Nigeria</p>
                        </div>
                    </div>
                </div>
                <div className=' lg:col-span-2 md:pt-8 bg-[#D1E1E1] p-4 rounded-b-[18px] '>
                <div className=' lg:pl-32'>
                <p className=' text-[#696767] '>Areje’s daily energy needs</p>
                <div className=' mb-5 my-3 flex gap-6'>
                    <p className=' inline-block p-2 text-[14px] text-[#2F2D2D] font-semibold bg-white rounded-xl'><img src={Plug} alt='Cake' className=' inline-block mb-1'/> <span className=''>26kw</span></p>

                </div>
                <p className=' text-[#696767] '>Areje’s basics</p>
                <div className=' mb-5 mt-3 flex gap-6'>
                    <p className=' inline-block p-2 text-[14px] text-[#2F2D2D] font-semibold bg-white rounded-xl'><img src={Cake} alt='Cake' className=' inline-block mb-1'/> <span className=''>39 yrs </span></p>
                    <p className=' inline-block p-2 text-[14px] text-[#2F2D2D] font-semibold bg-white rounded-xl'><img src={Edu} alt='Edu' className=' inline-block mb-1'/> <span className=''>Degree</span></p>

                </div>
                <div className=' pb-10'>
                    <FundingRCom
                        que='What is most important to me?'
                        answer='To keep creating value.'
                    />
                     <FundingRCom
                        que='Fun fact?'
                        answer=" I love reading and creating novel products"
                    />
                     <FundingRCom
                        que='What do I think about renewable energy? '
                        answer="It will keep production going when there's no electricity"
                    />
                </div>
                </div>
                       
                </div>
            </div>
        </div>
       <div>

       </div>
    </div>
   
    <div className=' flex justify-center gap-4  pt-2'>
    <div className=' ' >
            <div className=' flex justify-center gap-4'>
            <div className=' '> 
            <Link to='/campaign4'>
            <img src={Back} alt='Back'/>
            </Link>
            </div>
                <div>
                <Link to='/payment/paypal5/1'>
                <Button
                   name='Give'
                />
                </Link>
              
                </div>
              <div className=' '>
              <Link to='/campaign6'>
              <img src={Close} alt='Close'/>
              </Link>
            
              </div>
               
            </div>
        </div>
    
    </div>
   
    </div>
    
  )
}

export default Content5
