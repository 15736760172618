import React from 'react'
import { Outlet } from 'react-router-dom'
import Topsec from '../../Components/Payment/Topsec'

function Payment() {
  return (
    <div>
    {/* <Topsec/> */}
        <Outlet/>
    </div>
  )
}

export default Payment