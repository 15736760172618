import React, { useEffect, useState } from 'react'
import Facebook from '../../images/fac.svg'
import G from '../../images/g.svg'
import ButtonImage from '../SelectValue/ButtonImage'
import {  Link, useNavigate } from 'react-router-dom'
import { useUserAuth } from '../context/UserAuthContext'
import Loading from '../SelectValue/Loadingdropdown'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import swal from 'sweetalert';
import PinInput from 'react-pin-input';
function LoginForm() {
    const [num, setNum] = useState('')
    const [error, setError] = useState("");
    const [flag, setFlag] = useState(false);
    const [result, setResult] = useState("");
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false)
    const { googleSignIn, user,  setUpRecaptha,appleSignIn,  facebookSignIn} = useUserAuth();
    const navigate = useNavigate()
    const handleSubmit = async (e) =>{
            e.preventDefault()
            setLoading(true)
            setError("");
    if (num === "" || num === undefined){
        setLoading(false)
         swal("Please enter a valid phone number!");
    }else{
       
        try {
            setLoading(false)
            const response = await setUpRecaptha(num);
           
            setResult(response);
            setFlag(true);
          } catch (err) {
            setLoading(false)
            setError(err.message);
          }
    }
     
   
    }

    const verifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true)
        setError("");
        if (otp === "" || otp === null) return;
        try {
          await result.confirm(otp);
          setLoading(false)
        } catch (err) {
            setLoading(false)
          swal(err.message);
        }
      };
    const signinWithGoogle = async () =>{
        setLoading(true)
        try {
            await googleSignIn();
            //navigate("/dashboard");
          } catch (error) {
            setLoading(false)
            console.log(error.message);
          }
    }
    const signinWithApple = async () =>{
        setLoading(true)
        try {
            await appleSignIn();
            //navigate("/dashboard");
          } catch (error) {
            setLoading(false)
            console.log(error.message);
          }
    }
    const signinWithFacebook = async () =>{
        setLoading(true)
        try {
            await facebookSignIn();
            //navigate("/dashboard");
          } catch (error) {
            setLoading(false)
            console.log(error.message);
          }
    }
    useEffect(()=>{
        if(user){
            navigate('/campaign')
        }
    }, [user, navigate])
  return (
    <div className=' p-5 md:p-0 mt-10 flex flex-row justify-center'>
    <Loading
        open={loading}
    />
    
    <div>
    
       <h1 className=' text-header-text font-[700] text-[18px]  md:text-[30px] mb-7'>Sign in</h1>
        {/* {!flag && 
            <form  onSubmit={handleSubmit}>
        <div>
        <PhoneInput
           defaultCountry="NG"
            className=' p-3 border border-gray-300 rounded-lg'
            value={num}
            onChange={setNum} 

            />
        </div>
        <div id="recaptcha-container"></div>
        <div className=' mt-6 flex justify-center'>
        
        <YellowButton
                name='Continue'
            />
     
            
        </div>
       </form>
        }
      
       {flag &&
        <form   onSubmit={verifyOtp}>
        <div >
        <h1 className=' text-center mb-5 font-[600] md:text-[20px]'>Enter the 6 digit pin sent to your number {num}</h1>
        <div className=' flex justify-center'>
        <PinInput 
            length={6} 
            initialValue={otp}
            secret
            secretDelay={100} 
            onChange={(value, index) => {
                setOtp(value)
            }} 
            type="numeric" 
            inputMode="number"
            style={{padding: '10px'}}  
            inputStyle={{borderColor: '#B3B3B3', borderRadius: '8px'}}
            inputFocusStyle={{borderColor: 'blue'}}
            onComplete={(value, index) => {
                setOtp(value)
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
        </div>
           
            <div className=' mt-6 flex justify-center'>
             <YellowButton
                name='Continue'
            />
        </div>
        </div>
       </form>
       }
       
       <div className=' mt-8'>
            <div className=' flex justify-center gap-4'>
                <HorizontalRuleIcon /> 
                <p>Or</p>
                <HorizontalRuleIcon/> 
            </div> */}

            <div className=' mt-5'>
                    {/* <div className=' flex justify-center my-4'>
                        <ButtonCom
                            name= 'Continue with Apple'
                            img={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className=" inline-block bi bi-apple" viewBox="0 0 16 16">
                            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                            </svg>}
                            color=' bg-black text-white'
                            onClick={signinWithApple}
                        />
                    </div> */}
                    <div className=' grid grid-cols-1 my-6'>
                    <ButtonImage
                            name='Continue with Facebook'
                            imgg={Facebook}
                            color=' text-black font-[600]'
                            onClick={signinWithFacebook}
                        />
                    </div>
                   
                    <div className=' grid grid-cols-1 my-6'>
                        <ButtonImage
                            name='Continue with Google'
                            imgg={G}
                            color=' text-black font-[600]'
                            onClick={signinWithGoogle}
                        />
                    </div>
                    <p className='  text-[16px] mt-7 font-[600] text-[#202D2D]'>New user? <Link to='/signup'><span className=' underline'>Create an account to receive project updates</span> </Link> </p>
            </div>
       </div>
    </div>
       
   
  )
}

export default LoginForm