import React from 'react'
import LoginForm from '../../Components/Login/LoginForm'
import Navback from '../../Components/NavBack'

function Login() {
  return (
    <div>
        <Navback/>
        <LoginForm/>
    </div>
  )
}

export default Login