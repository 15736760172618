import React from 'react'
import { useUserAuth } from '../context/UserAuthContext';
import G from '../../images/ggoo.svg'
function Linked() {
  const { user } = useUserAuth();
  const connectAcc = user.providerData[0].providerId
  
  return (
    <div>
        <div >
         { connectAcc === 'google.com' ?
         <div className=' flex gap-5 rounded-[99px] border-gray-200 border-2 p-3'>
                        <div className=' '>
                        <img src={G} alt='google'/>
                        </div>
                       
                                <p className=' font-[600]  text-primary-color'>Connected with facebook </p>
                        </div>
                        : connectAcc === 'facebook.com' ?
                        <div className=' flex gap-5 rounded-[99px] border-gray-200 border-2 p-3'>
                        <div className=' '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="blue" className="inline-block bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                </svg>
                        </div>
                       
                                <p className=' font-[600]  text-primary-color'>Connected with facebook </p>
                        </div>
                        
                        : null
         }
        </div>
    </div>
  )
}

export default Linked