import {BrowserRouter, Route,  Routes} from 'react-router-dom';
import './App.css';
import { UserAuthContextProvider } from './Components/context/UserAuthContext';
import Dashboard from './Pages/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Select from './Pages/Select';
import Signup from './Pages/Signup';
import WOW from 'wowjs';
import ProtectedRoute from './Protected/ProtectedRoute';
import { useEffect } from 'react';
import Contact from './Pages/Contactus';
import Projects from './Pages/Projects';
import Aboutus from './Pages/Aboutus';
import Payment1 from './Components/Project/Payment1';
import Payment2 from './Components/Project/Payment2';
import Payment3 from './Components/Project/Payment3';
import Payment4 from './Components/Project/payment4';
import Payment5 from './Components/Project/Payment5';
import Payment6 from './Components/Project/Payment6';
import PaymentSuccess from './Components/Project/PaymentSuccess';
import Terms from './Pages/terms';
import Privacy from './Pages/Privacy';

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <div >
      <BrowserRouter>
      <UserAuthContextProvider>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/aboutus' element={<Aboutus/>}/>
          <Route path='/contacts' element={<Contact/>}/>
          <Route path='donate1/:amount' element={<Payment1/>}/>
          <Route path='donate2/:amount' element={<Payment2/>}/>
          <Route path='donate3/:amount' element={<Payment3/>}/>
          <Route path='donate4/:amount' element={<Payment4/>}/>
          <Route path='donate5/:amount' element={<Payment5/>}/>
          <Route path='donate6/:amount' element={<Payment6/>}/>
          <Route path='sucess' element={<PaymentSuccess/>}/>
          <Route path='/project' element={<Projects/>}/>
          <Route path='/terms' element={<Terms/>}/>
          <Route path='/privacy' element={<Privacy/>}/>
          <Route />
          <Route path='/signupoptions' element={<Select/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>
          
          <Route path='*' element={<ProtectedRoute><Dashboard/></ProtectedRoute> }/>
        </Routes>
        </UserAuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
