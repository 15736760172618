import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Content2 from '../../Components/Campaign/content2'
import Content3 from '../../Components/Campaign/content3'
import Content4 from '../../Components/Campaign/Content4'
import Content5 from '../../Components/Campaign/Content5'
import Content6 from '../../Components/Campaign/Content6'
import CreditCardPayment1 from '../../Components/Payment/CreditCardPayment1'
import PaypalPayment from '../../Components/Payment/PaypalPayment'
import PaypalPayment1 from '../../Components/Payment/PaypalPayment1'
import PaypalPayment2 from '../../Components/Payment/PaypalPayment2'
import PaypalPayment4 from '../../Components/Payment/PaypalPayment4'
import PaypalPayment5 from '../../Components/Payment/PaypalPayment5'
import PaypalPayment6 from '../../Components/Payment/PaypalPayment6'
import Success from '../../Components/Payment/Success'
import Contactus from '../../Components/Settings/Contactus'
import Campaign from '../Campaign'
import Payment from '../Payment'
import CreditCard from '../Payment/CreditCard'
import Settings from '../Settings'


function Routees() {

  return (
    <div>
        <Routes>
            <Route path='/campaign' element={<Campaign/>}/>
            <Route path='/campaign2' element={<Content2/>}/>
            <Route path='/campaign3' element={<Content3/>}/>
            <Route path='/campaign4' element={<Content4/>}/>
            <Route path='/campaign5' element={<Content5/>}/>
            <Route path='/campaign6' element={<Content6/>}/>
            <Route path='/payment' element={<Payment/>}>
            <Route path='credit' element= {<CreditCard/>}/>
            <Route path='credit1' element= {<CreditCardPayment1/>}/>
            <Route path='paypal/:amount' element={<PaypalPayment/>}/>
            <Route path='paypal2/:amount' element={<PaypalPayment1/>}/>
            <Route path='paypal3/:amount' element={<PaypalPayment2/>}/>
            <Route path='paypal4/:amount' element={<PaypalPayment4/>}/>
            <Route path='paypal5/:amount' element={<PaypalPayment5/>}/>
            <Route path='paypal6/:amount' element={<PaypalPayment6/>}/>
            </Route>
            <Route path='/contact' element={<Contactus/>}/>
            <Route path='/success' element={<Success/>}/>
            <Route path='/setting' element={<Settings/>}/>
        </Routes>
    </div>
  )
}

export default Routees