import React from 'react'

function Privacy() {
  return (
    <div className=' px-10 pt-5'>
    <h1 className=' text-2xl mb-3'>Privacy</h1>
        Privacy is of utmost importance at Boosty, the solar crowdfunding startup. We are committed to protecting the personal information of our users, investors, and stakeholders. This Privacy Policy outlines how we collect, use, disclose, and protect your data. By using our platform and services, you agree to the terms described below.
Information Collection and Usage:
Personal Information: We may collect certain personal information during the registration process, such as your name, email address, contact number, and billing information. This information is necessary for providing our services and ensuring a secure user experience.
Non-Personal Information: We also collect non-personal information, including but not limited to device information, cookies, and usage data. This data is used to enhance our services, improve user experience, and analyze trends.
Data Protection:
Security Measures: Boosty employs industry-standard security measures to protect your personal and non-personal data from unauthorized access, disclosure, or alteration. We use encryption, firewalls, access controls, and other security protocols to safeguard your information.
Data Access: Access to your personal information is restricted to authorized personnel only, who require it for legitimate business purposes.
Use of Information:
Service Delivery: Boosty uses your personal information to deliver the services you have requested, such as processing transactions, managing your crowdfunding campaigns, and communicating with you.
Communication: We may send you updates, newsletters, or marketing materials related to Boosty or its solar projects. You can choose to opt-out of these communications at any time.
Aggregated Data: We may use non-personal information in an aggregated and anonymized form for statistical analysis, research, and reporting purposes.
Sharing and Disclosure:
Third-Party Service Providers: Boosty may share your personal information with trusted third-party service providers to perform tasks on our behalf, such as payment processing, data analysis, and customer support.
Legal Obligations: We may disclose your information if required by law or in response to a valid legal request, such as a court order or government investigation.
Business Transfers: In the event of a merger, acquisition, or sale of all or part of Boosty, your information may be transferred to the new entity.
Cookies and Tracking:
Cookies: Boosty uses cookies and similar technologies to track user preferences, analyze website traffic, and customize your experience on our platform.
Opt-Out: You can adjust your browser settings to reject cookies or notify you when they are being used. However, disabling cookies may affect certain functionalities on our website.
Children's Privacy:
Boosty's services are not intended for children under the age of 13. We do not knowingly collect personal information from children without parental consent.
Changes to Privacy Policy:
Boosty reserves the right to modify this Privacy Policy periodically. Any updates will be posted on our website, and the revised policy will be effective from the date of posting.
If you have any questions or concerns about our privacy practices or wish to exercise your data rights, please contact us at info@boosty.com.


    </div>
  )
}

export default Privacy