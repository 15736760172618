import React from 'react'

function Button({name, onClick}) {
  return (
    <>
     <button 
      className=' bg-primary-color px-[18px] py-[10px] font-[700] border-none rounded-[99px] text-button-color'
      onClick={onClick}
     >{name}</button>   
    </>
  )
}

export default Button