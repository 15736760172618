import React from 'react'
import Footer from '../../Components/Footer'
import Contactus from '../../Components/Home/Contactus'
import Impart from '../../Components/Home/Impart'
import LongTerm from '../../Components/Home/LongTerm'
import Mx from '../../Components/Home/Mx'
import Navbar from '../../Components/Home/Navbar'
import Topsec from '../../Components/Home/Topsec'
import Working from '../../Components/Home/Working'
import Mobile from '../../Components/Mobile'
import CustomArrows from '../../Components/Home/Projects'

function Home() {
  return (
    <div>
    <div className=' hidden lg:block'>
    <Navbar/>
        <Topsec/>
        <Working/>
        <Impart/>
        <LongTerm/>
        <CustomArrows/>
        <Mx/>
        <Contactus/>
        <Footer/>
    </div>
        <div className=' lg:hidden block'>
        <Mobile/>
        </div>
    </div>
  )
}

export default Home