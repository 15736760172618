import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CancelIcon from '@mui/icons-material/Cancel';
import TextBox from './SelectValue/TextBox';
import Textarea from './SelectValue/Textarea';
import AddsButtion from './SelectValue/AddButtion';
import { toast } from 'react-toastify'
import { addDoc, collection, } from 'firebase/firestore'
import { db } from '../firebase-config'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
function FeedbackModal({open, handleClose}) {
    const [email, setEmail] = useState('')
    const [sub, setSub] = useState('')
    const [mess, setMess] = useState('')

  const sendmail = (e) =>{
    e.preventDefault()
    toast.success('Mail sent successfully!!')
   
    addDoc(collection(db, "mails"),{
      
        to: 'info@boostytech.com',
        message: {
          subject: 'Website feedback',
          html: `<div> <p> Hello, ${email} just filled the filled back with a subjuct of - ${sub}. Below is the feedback.<br></br> ${mess} </p> </div>` ,
        },
      }
    )
    handleClose()
    setEmail('')
    setMess('')
    setSub('')
  }
  return (
    <div>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='bg-[#F8FAFA] p-4'>
            <div className=' flex mb-4 justify-end'>
                    <CancelIcon onClick={handleClose}/>
            </div>
            <div className=''>
            <div className=' w-full'>
        <h1 className=' text-[23px] sm:text-[25px] md:text-[25px] font-[700] text-header-text mb-3'>Hey, how was your website experience</h1>
       <p className=' text-[16px] font-[600] text-[#3E3D3D]'>We would love to get your honest feedback. Did you like the website? Was it easy to find what you came here for? </p>
            <div className=' w-full'>
            <form onSubmit={sendmail}>
               
              <div>

             
               <div className=' my-3'>
               <TextBox
                   place='Your email'
                   type='email'
                   onChange={setEmail}
                   value={email}
                   color=' bg-[#EFF0F2]'
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your subject'
                   type='text'
                   value={sub}
                   onChange={setSub}
                   color=' bg-[#EFF0F2]'
               />
               </div>
               <div className=' min-w-fit relative my-5'>
                   <Textarea 
                       place='Your message'
                       onChange={setMess}
                       value={mess}
                       color=' bg-[#EFF0F2]'
                   />
                   <div className=' absolute bottom-3 right-2 '>
                   <AddsButtion
                       name='Send'
                   />
               </div>
               </div>
               
                </div>
           </form>
            </div>
           
        </div>
            </div>
        </div>
      </Dialog>
    </div>
  )
}

export default FeedbackModal