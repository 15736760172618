import React from 'react'
import { useNavigate } from 'react-router-dom';
import BackA from '../images/barrow.svg';
function Navback() {
    const navigate = useNavigate();
  return (
    <div className=' py-5 flex justify-between px-7'>
        <div className='cursor-pointer' onClick={() => navigate(-1)}>
       <img src={BackA} alt='Back'/>
    </div>
            
      
    </div>
  )
}

export default Navback