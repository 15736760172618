import React from 'react'
import Te1 from '../../images/funmi.png'
import Te2 from '../../images/te2.png'
import Te3 from '../../images/te3.png'
import Te4 from '../../images/te4.png'
import Te5 from '../../images/ja.jpeg'
import TeamCom from './TeamCom'
function Team() {
  return (
    <div className='mt-[96px]  grid sm:p-3 px-10 sm:grid-cols-3' id='team'>
        <div className=' sm:col-span-2 sm:pl-9'>
        <h1 className=' font-[700] text-[25px] text-[#2D2B2B] mb-4'>The team</h1>
        <div className=' mb-[40px]'>
            <TeamCom
                img={Te1}
                name='Olufunmilola Sopein-Mann'
                link='https://www.linkedin.com/in/oluwafunmilola-sopein-mann-09a71873'
                title='Project lead'
                con={<p>
                    A business developer and strategist  with 10+ Yrs of experience.
                        <br></br><br></br>
                    Funmi is an alumni of the Entrepreneurship in Emerging  Economies Program at Harvard Online  Business School, and the DMZ Toronto Women Founders Program/ Black Innovation Program.
                </p>}
            />
        </div>
        <div className=' mb-[40px]'>
            <TeamCom
                img={Te2}
                name='Aimua Ogboghodor'
                link='https://www.linkedin.com/in/aimuan/'
                title='Product Designer/Strategist'
                con={<p>
                    A serial entrepreneur, visual artist, product strategist and product designer with experience developing products of companies like Canada Post, ApplyFast and Riizolvd.
<br></br><br></br>
Aimua has 15+ Years in advertising and experiential marketing working on brands like Virgin Atlantic, Unilever, Nokia, and MTN.
<br></br><br></br>
He is a UX/product design mentor at adplist.org and Founder Institute, and an alumni of the DMZ Toronto  Black Innovation Program.
                </p>}
            />
        </div>
        <div className=' mb-[40px]'>
        <TeamCom
                img={Te4}
                name='David Ajibade'
                title='Software Engineer'
                link='https://www.linkedin.com/in/david-ajibade-69a4a5170/'
                con={<p>
                   David is an accomplished software engineer with a passion for developing innovative web and mobile applications. With over 5 years of hands-on experience, David has a deep understanding of software development principles and a track record of delivering high-quality solutions.
                   <br></br><br></br>
                    David is dedicated to staying up to date with the latest trends and advancements in the software engineering field. He is always attending conferences, participating in online courses, and contributing to open-source projects.
                </p>}
            />
           
        </div>
        <div className='mb-[40px]'>
        <TeamCom
                img={Te5}
                name='Dr. James C.'
                link='https://www.linkedin.com/in/james-c-ofuase-pmp%C2%AE-coren-60441b97?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
                title='Chief System Engineer'
                con={<p>
                   Dr. James C. Ofuase is a PMP-certified Project Manager and Power Engineer with 14+ years of experience in off-grid clean energy solutions and innovative financing models. An advocate for clean energy and climate change mitigation, he excels in regulatory frameworks, communication, and problem-solving.
                   <br></br><br></br>
                 His achievements include securing financing for off-grid projects, leading power project design and commissioning, and authoring a PhD thesis on electric vehicles' impact on distribution systems. Recognized as a World Bank Climate Change Leader, Dr. Ofuase ensures high-impact project delivery in the energy sector.
                </p>}
            />
        </div>
        <div className=' mb-[120px]'>
        <TeamCom
                img={Te3}
                name='Ido Sarig'
                link='https://www.linkedin.com/in/idosarig/'
                title='Advisory board'
                con={<p>
                    Ido is a product marketing and corporate development executive.
                   <br></br><br></br>
                    He is chief marketing officer at Indiggo and a former venture partner with TWVP.
                    <br></br><br></br>
                    He is also CEO, coach and mentor at Alchemist, Next 47, ICON SV and StartUpWind.
                </p>}
            />
        </div>
       
        </div>
    </div>
  )
}

export default Team