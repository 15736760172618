import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  OAuthProvider,
} from "firebase/auth";
import { authentication, auth } from "../../firebase-config";


const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  

  function logOut() {
    return signOut(authentication);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(authentication, googleAuthProvider);
  }
  function facebookSignIn(){
    const facebookProvider = new FacebookAuthProvider();
    return signInWithPopup(authentication, facebookProvider);
  }
  function appleSignIn(){
    const provider = new OAuthProvider('apple.com');
    return signInWithPopup(auth, provider);
  }
  
  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      authentication
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(authentication, number, recaptchaVerifier);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, (currentuser) => {
      console.log("testtt", currentuser);
      setUser(currentuser);
    })
    
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logOut,
        googleSignIn,
        facebookSignIn,
        setUpRecaptha,
        appleSignIn
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
