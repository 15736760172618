import React from 'react'
import Navbar from '../../Components/Home/Navbar'
import Project from '../../Components/Project/Project'
import Footer from '../../Components/Footer'
import Mobile from '../../Components/Mobile'

function Projects() {
  return (
    <div>
    <div className=' hidden lg:block'>
    <Navbar/>
    <div className=' pt-20'>
    <Project/>
    </div>
   <Footer/>
   </div>
   <div className=' lg:hidden block'>
        <Mobile/>
        </div>
    </div>
  )
}

export default Projects