
function AddsButtion({onClick, name}) {
  return (
    
     <>
     <button 
      className=' bg-primary-color2 px-[14px] py-[8px] border-[#374646] border-2 rounded-[99px] text-[#202D2D]'
      onClick={onClick}
     >{name} </button>   
    </>
    
  )
}

export default AddsButtion