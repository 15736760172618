import React from 'react'

function Terms() {
  return (
    <div className=' pt-9 px-10'>
       <h1 className=' text-2xl'> Boosty Solar Crowdfunding Terms of Service</h1>
Welcome to Boosty! These Terms of Service ("TOS") govern your use of the Boosty platform and services. By accessing or using our platform, you agree to abide by these terms. If you do not agree with any part of these TOS, please refrain from using our services.
1. Eligibility and Registration
1.1 Age Requirement: To use Boosty's services, you must be at least 18 years old. By registering, you confirm that you meet this age requirement.
1.2 Account Registration: You may be required to create an account to access certain features of the platform. You are responsible for maintaining the confidentiality of your account credentials and agree to be solely responsible for all activities that occur under your account.
2. Solar Crowdfunding Platform
2.1 Boosty Platform: Boosty provides a crowdfunding platform that allows users to support solar projects and initiatives by contributing funds.
2.2 Project Creator Responsibilities: If you create a solar crowdfunding project on Boosty, you represent and warrant that you have the necessary rights, permissions, and licenses to carry out the project. You are responsible for providing accurate information about the project, its funding goals, and its execution.
2.3 User Contributions: By contributing funds to a solar project on Boosty, you understand and acknowledge that the funds will be used for the designated project and that no guarantees or warranties are made regarding the success, outcome, or performance of the project.
2.4 Project Completion and Refunds: Boosty endeavors to ensure that projects are executed as described. However, project outcomes may be affected by various factors. In case a project cannot be completed as planned, Boosty will make reasonable efforts to offer refunds to eligible contributors.
3. Use of Services
3.1 Prohibited Activities: You agree not to use Boosty's services for any unlawful, abusive, or harmful purposes. Prohibited activities include, but are not limited to, fraud, harassment, spamming, and infringing on intellectual property rights.
3.2 Compliance with Laws: You agree to comply with all applicable laws, regulations, and third-party rights when using Boosty's platform.
4. Intellectual Property
4.1 Boosty's Content: All content, including logos, text, graphics, images, and software, provided by Boosty is protected by intellectual property rights and remains the property of Boosty or its licensors.
4.2 User Content: You retain ownership of the content you submit to the platform, and you grant Boosty a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and display such content for the purpose of providing our services.
5. Privacy
5.1 Privacy Policy: By using Boosty's services, you consent to the collection, use, and disclosure of your information as described in our Privacy Policy.
6. Termination
6.1 Termination by Boosty: Boosty reserves the right to terminate or suspend your access to the platform at any time, with or without cause, and without prior notice.
6.2 Termination by User: You may terminate your account by contacting Boosty's customer support or following the account closure procedure available on the platform.
7. Disclaimer of Warranties
7.1 Boosty provides its platform and services on an "as is" basis. We make no warranties or representations regarding the accuracy, reliability, or completeness of the content or the availability, functionality, or security of our platform.
8. Limitation of Liability
8.1 In no event shall Boosty be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the platform or services.
9. Modifications to the TOS
9.1 Boosty may update these TOS from time to time. We will notify you of significant changes, and your continued use of the platform after such changes constitute your acceptance of the updated TOS.
10. Governing Law
10.1 These Terms of Service shall be governed by and construed in accordance with the laws of Nigeria, without regard to its conflict of laws principles.
11. Contact Us
11.1 If you have any questions or concerns regarding these TOS or Boosty's services, please contact us at info@boosty.com.


    </div>
  )
}

export default Terms