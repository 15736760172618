import React, { useState } from 'react'
import TextBox from '../SelectValue/TextBox'
import Textarea from '../SelectValue/Textarea'
import AddsButtion from '../SelectValue/AddButtion'
import { toast, ToastContainer } from 'react-toastify'
import { addDoc, collection, } from 'firebase/firestore'
import { db } from '../../firebase-config'
import 'react-toastify/dist/ReactToastify.css';


function Contacts() {
    const [email, setEmail] = useState('')
    const [sub, setSub] = useState('')
    const [mess, setMess] = useState('')

    const sendmail = (e) =>{
        e.preventDefault()
        toast.success('Mail sent successfully!!')
        addDoc(collection(db, "mails"),{
          
            to: 'info@boostytech.com',
            message: {
              subject: 'Contact us',
              html: `<div> <p> Hello, ${email} sent a contact us message with a subject of - ${sub}. Below is the message.<br></br> ${mess} </p> </div>` ,
            },
          }
        )
        setEmail('')
        setMess('')
        setSub('')
      }
  return (
    
    <>
    <ToastContainer/>
    <div className=' mx-auto w-1/2 mt-10 rounded-xl  bg-[#F8FAFA] p-16'>
        <div>
            <h1 className=' text-[#2D2B2B] text-[25px] font-[700] mb-4'>You need any help?</h1>
            <p className=' text-[#3E3D3D] text-[16px] font-[600]'>Send us a message. We would love to hear from you.</p>
            <form onSubmit={sendmail}>
               
               <div className=' my-3'>
               <TextBox
                   place='Your email'
                   type='email'
                   onChange={setEmail}
                   value={email}
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your subject'
                   type='text'
                   value={sub}
                   onChange={setSub}
               />
               </div>
               <div className='  relative my-5'>
                   <Textarea
                       place='Your message'
                       name='message'
                       onChange={setMess}
                       value={mess}
                   />
                   <div className=' absolute bottom-3 right-2 '>
                   <AddsButtion
                       name='Send'
                   />
               </div>
               </div>
               
           </form>
        </div>
    </div>
    <p className=' pt-[48px] pb-[96px] text-center text-[#3E3D3D] text-[16px] font-[400] '>For general inquiries, call us at <span className=' font-[700]'>1.647.834.7844</span></p>
    </>
  )
}

export default Contacts