import React, { useState} from 'react'
import AddsButtion from '../SelectValue/AddButtion'
import Textarea from '../SelectValue/Textarea'
import TextBox from '../SelectValue/TextBox'
import { addDoc, collection, } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Logo from '../../images/boostylogo.png'
import { Link } from 'react-router-dom'
function Contactus() {
  const [email, setEmail] = useState('')
  const [sub, setSub] = useState('')
  const [mess, setMess] = useState('')

  const sendmail = (e) =>{
      e.preventDefault()
      toast.success('Mail sent successfully!!')
      addDoc(collection(db, "mails"),{
        
          to: 'info@boostytech.com',
          message: {
            subject: 'Contact us',
            html: `<div> <p> Hello, ${email} sent a contact us message with a subject of - ${sub}. Below is the message.<br></br> ${mess} </p> </div>` ,
          },
        }
      )
      setEmail('')
      setMess('')
      setSub('')
    }
  return (
    <div>

<div className=' '>
    <Link to='/'>
      <img src={Logo} className=' mx-auto'  alt='Logo' />
      </Link>
    </div>
    <div className=' bg-white   rounded-t-2xl py-[16px]   lg:pt-[96px] lg:pb-[120px]' id='contact'>
 
    <ToastContainer />
        <div className=' lg:w-[70vh]  mx-auto'>
        <div className=' w-full'>
        <h1 className=' text-[23px] sm:text-[25px] md:text-[25px] font-[700] text-header-text mb-3'>You need any help?</h1>
       <p className=' text-[16px] font-[600] text-[#3E3D3D]'>Send us a message. We would love to hear from you.</p>
            <div className=' w-full'>
            <form  onSubmit={sendmail}>
               
            <div className=' my-3'>
               <TextBox
                   place='Your email'
                   type='email'
                   onChange={setEmail}
                   value={email}
               />
               </div>
               <div className=' my-3'>
               <TextBox
                   place='Your subject'
                   type='text'
                   value={sub}
                   onChange={setSub}
               />
               </div>
               <div className='  relative my-5'>
                   <Textarea
                       place='Your message'
                       name='message'
                       onChange={setMess}
                       value={mess}
                   />
                   <div className=' absolute bottom-3 right-2 '>
                   <AddsButtion
                       name='Send'
                   />
               </div>
               </div>
               
           </form>
            </div>
           
        </div>
        </div>
    </div>
    </div>
  )
}

export default Contactus