import React from 'react'

function FullyFundedCom({img, name, sub, per,price, backers}) {
  return (
    <div className=' mb-4'>
         <div className=' p-2 pt-4 flex justify-start gap-2  mb-2 pb-4 bg-white  '>
        <div>
        <img src={img} width='50' height='50'  alt='First' className='  mx-auto'/>
        </div>
      
        <div>
          <p className=' text-[16px] font-bold'>{name}</p>
          <p className=' text-[14px] font-semibold text-[#878787]'>{sub}</p>
        </div>
        <p></p>
     </div>
     <div className=' flex mt-4 gap-4'>
        <div>
            <p className=' text-button-color font-bold  text-[12px]'>{per}</p>
            <p className=' text-button-color font-bold  text-[12px]'>funded</p>
        </div>
        <div>
           <p className=' text-[#6C6868] font-bold  text-[12px]'>{price}</p>
            <p className=' text-[#6C6868] font-bold  text-[12px]'>you donated</p>
        </div>
        <div>
            <p className=' text-[#6C6868] font-bold  text-[12px]'>{backers}</p>
            <p className=' text-[#6C6868] font-bold  text-[12px]'>backers</p>
        </div>
        
        <div></div>
     </div>
    </div>
  )
}

export default FullyFundedCom