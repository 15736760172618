import React from 'react'
import { Link } from 'react-router-dom'
import AddsButtion from '../SelectValue/AddButtion'
import Button from '../SelectValue/Button'

function Topsec() {
  return (
    <div className=' pt-20'>

   
    <div className=' mx-7  rounded-[18px] bg'>
            <div className='grid h-[80vh]   place-items-center '>
                 <div className='bg-[#D6D6D6] wow animate__animated animate__fadeInLeft  md:w-1/2 mx-3 rounded-[18px] opacity-[0.82] p-6'>
                        <h1 className=' text-header-text text-[18px] md:text-[30px] font-[700]'>Help people in energy-vulnerable communities transition to sustainable energy</h1>
                        <p className=' text-sub-text pt-2 text-[16px] font-[600]'>Donate to verified micro projects and start making a difference now! If you already have an account, sign in to use Boosty.</p>
                        <div className=' flex mt-3 gap-4'>
                          <Link to='/project'>
                          <Button
                                name='Start giving'
                            />
                          </Link>
                          <Link to='/login'>
                            <AddsButtion
                                name='Sign in'
                            />
                            </Link>
                        </div>
                    </div>
            </div>
    </div>
    </div>
  )
}

export default Topsec