
import { doc, setDoc } from "firebase/firestore"; 

import { db } from '../../firebase-config';

export const createRecord = async (userId, title, data,) => {
    
    try {
      // Add the record to the "records" collection with the user ID as the document ID
     const response =  await setDoc(doc(db, title, userId), data);
      console.log("Record created successfully!");
      return response
    } catch (error) {
      console.error("Error creating record:", error);
    }
  };

  