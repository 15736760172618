import  React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import First from '../../images/avata.jpeg'
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';

import {  Link, useLocation} from 'react-router-dom';
import Routees from './Routes';
import Sidebar from '../../Components/Dashboard/Sidebar';
import { useUserAuth } from '../../Components/context/UserAuthContext';
import { doc, getDoc, } from "firebase/firestore";
import { db } from '../../firebase-config';
const drawerWidth = 350;




function Dashboard(props) {
    const { window } = props;
    const {user} = useUserAuth()
    const location = useLocation();
    const uuid = user.uid
    const [donationValues, setDonationValue] = useState(0)
    console.log(location)
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const unSub= async () =>{
    const docRef = doc(db,  'donations', uuid);
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      console.log("Document data:bbbbb", docSnap.data().donation);
      
      setDonationValue(docSnap.data().donation)

    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!jjjhhh");
    }
  }

  useEffect(()=>{
  unSub()
  })
  
  const drawer = (
    <div>
     <div className=' p-2 pt-4 flex justify-start gap-2  mb-2 pb-4 bg-white  '>
        <div>
        <img src={ user.photoURL === null ? First :  `${user.photoURL}`} width='48' height='48' alt='First' className=' rounded-full  mx-auto'/>
        </div>
      
        <div>
          <p className=' text-[20px] font-bold'>{user.displayName}</p>
          {/* <img src={Coin}  alt='Coin' className='inline-block  mx-auto'/>
          <p className=' inline-block text-[12px] font-bold'>2,222</p> */}
          <p className=' text-[12px] font-semibold text-[#878787]'>${donationValues} donated</p>
        </div>
        <p></p>
     </div>
     <div className=' mt-8 px-2'>
     <Sidebar/>
     </div>
     
      
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div>
   
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
       
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height:"2px",
          ml: { sm: `${drawerWidth}px` },
         backgroundColor: '#fff',
        }}
      >
      <div className=' flex justify-between'>
      <div className=' flex justify-between'>
      <Toolbar>
      <IconButton
            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
         
                
       
        </Toolbar>
        <div className=' '>
          <p className=' text-black  font-[400]'>
           </p>
          
         </div>
          
         
         
      </div>
      <div className='sm:pr-16  mx-auto '>
      {/* <Link to='/'>
      <img src={Logo}  alt='Logo' />
      </Link> */}
      
      </div>
    
            
                 </div>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white' },
          }}
        >
          {drawer}
          

        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,  backgroundColor: 'white' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        className=' px-3'
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
      <div className=' w-full' >
         <Routees/>
      </div>
        
      </Box>
    </Box>
    </div>
  )
}
Dashboard.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Dashboard