import React, { useState } from 'react'
import Feedback from '../images/web.svg'
import Ms from '../images/mxF.png'
import Linked from '../images/linkIn.svg'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import FeedbackModal from './FeedbackModal';
function Footer() {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>

   <FeedbackModal
    open={open}
    handleClose={handleClose}
   />
    <div className='md:px-48 px-2 py-5 bg-[#374646] flex justify-between'>
    <div className=' sm:flex sm:flex-col sm:justify-center'>
        <p className=' text-[#F3B921] cursor-pointer' onClick={handleOpen}><img src={Feedback} className=' inline-block' alt=' f' /> Website feedback</p>
    </div>
    <div>
    <img src={Ms} alt='Microsoft bage'/>
    </div>
    <div className=' flex justify-center  flex-col mt-1 gap-5'>
    <div>
    <a href='https://www.facebook.com/boostytech/' className=' px-3' target='_blank' rel='noreferrer'>
    <FacebookOutlinedIcon className='text-[#F3B921] '/>
    </a>
    <a href='https://www.linkedin.com/company/boosty-inc' target='_blank' rel='noreferrer'>
    <img src={Linked} className=' inline-block' alt='Linked'/>
    </a>
   
    </div>
   
    </div>
    <div className=' sm:flex sm:flex-col sm:justify-center'>
        <p className=' text-[#F3B921]'>© Boosty Inc</p>
    </div>
    </div>
    </>
  )
}

export default Footer