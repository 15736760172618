import React from 'react'

function FundingRCom({que, answer}) {
  return (
    <div className='my-6 md:my-10 py-7  md:w-3/4 p-5 bg-white rounded-[22px]'>
        <p className=' font-normal md:text-[18px] text-sub-text mb-2'>{que}</p>
        <h1 className=' font-bold text-[28px] text-header-text'>{answer}</h1>
    </div>
  )
}

export default FundingRCom