import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DonationCom from './DonationCom';
import One from '../../images/c3.png'
import Sec from '../../images/c2.png'
import third from '../../images/c1.png'
import fo from '../../images/c4.png'
import fiv from '../../images/c5.png'
import six from '../../images/c44.png'
import FullyFundedCom from './FullyFundedCom';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useUserAuth } from '../context/UserAuthContext';
import { NavLink } from 'react-router-dom';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid rgba(255, 255, 255, 1)`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
    border:' 1px solid rgba(255, 255, 255, 1)' ,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 1)'
      : 'rgba(255, 255, 255, 1)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
 
}));

export default function Sidebar() {
  const [expanded, setExpanded] = React.useState('panel1');
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const {logOut, }  = useUserAuth()
  const Logout = async ()=>{
    try {
      await logOut();
      //navigate("/dashboard");
    } catch (error) {
      console.log(error.message);
    }
  }
 const[backed1, setBacked1] = useState(0)
 const[backed2, setBacked2] = useState(0)
 const[backed3, setBacked3] = useState(0)
 const[backed4, setBacked4] = useState(0)
 const[backed5, setBacked5] = useState(0)
 const[backed6, setBacked6] = useState(0)
  const [amount1, setAmount1] = useState(0)
  const [kPrevDonation, setKPrevDonation] = useState(0)
  const [ePrevDonation, setePrevDonation] = useState(0)
  const [aPrevDonation, setaPrevDonation] = useState(0)
  const [jPrevDonation, setJPrevDonation] = useState(0)
  const [rPrevDonation, setRPrevDonation] = useState(0)
  const [bPrevDonation, setBPrevDonation] = useState(0)
  const { user } = useUserAuth();
  const uuid = user.uid
  const value1 = (amount1/ 15941.25) * 100
  const reducedValue1 = Number(value1.toFixed(1));
  const [amount2, setAmount2] = useState(0)
const value2 = (amount2/ 11019.81) * 100
const reducedValue2 = Number(value2.toFixed(1));
const [amount3, setAmount3] = useState(0)
const value3 = (amount3/ 5201.83) * 100
const reducedValue3 = Number(value3.toFixed(1));
const [amount4, setAmount4] = useState(0)
const value4 = (amount4/ 6729.23) * 100
const reducedValue4 = Number(value4.toFixed(1));

const [amount5, setAmount5] = useState(0)
const value5 = (amount5/ 4747.50) * 100
const reducedValue5 = Number(value5.toFixed(1));

const [amount6, setAmount6] = useState(0)
const value6 = (amount6/ 7561.03) * 100
const reducedValue6 = Number(value6.toFixed(1));

const[a, setA] = useState(0)
const[b, setB] = useState(0)
const[c, setC] = useState(0)
const[d, setD] = useState(0)
const[e, setE] = useState(0)
const[f, setF] = useState(0)

const check = () =>{
  if(kPrevDonation > 0){
    setA(1)
  }else{
    setA(0)
  }
  if(aPrevDonation > 0){
    setB(1)
  }else{
    setB(0)
  }
  if(ePrevDonation > 0){
    setC(1)
  }else{
    setC(0)
  }
  if(jPrevDonation > 0){
    setD(1)
  }else{
    setD(0)
  }
  if(rPrevDonation > 0){
    setE(1)
  }else{
    setE(0)
  }
  if(bPrevDonation > 0){
    setF(1)
  }else{
    setF(0)
  }
  
}








const unSub = async () =>{
  const docRef = doc(db, "kindeDonation", 'kkkbosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setAmount1(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
 
const unSub2 = async () =>{
  const docRef = doc(db, "abiodunDonation", 'aaabosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setAmount2(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

const unSub3 = async () =>{
  const docRef = doc(db, "emmaDonation", 'eeebosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setAmount3(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub4 = async () =>{
  const docRef = doc(db, "kindeDonation", 'kkkbacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked1(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub5 = async () =>{
  const docRef = doc(db, "abiodunDonation", 'aaabacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked2(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub6 = async () =>{
  const docRef = doc(db, "emmaDonation", 'eeebacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked3(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub7 = async () =>{
  const docRef = doc(db,  'kdonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setKPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub8 = async () =>{
  const docRef = doc(db,  'adonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setaPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub9 = async () =>{
  const docRef = doc(db,  'edonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setePrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub10 = async () =>{
  const docRef = doc(db,  'jdonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setJPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub11 = async () =>{
  const docRef = doc(db,  'rdonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setRPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub12 = async () =>{
  const docRef = doc(db,  'udonations',uuid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setBPrevDonation(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub13 = async () =>{
  const docRef = doc(db, "joyDonation", 'jjjbacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked4(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub14 = async () =>{
  const docRef = doc(db, "ArejeDonation", 'reebacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked5(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub15 = async () =>{
  const docRef = doc(db, "bukolaDonation", 'buubacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked6(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub16= async () =>{
  const docRef = doc(db, "joyDonation", 'jjjbosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setAmount4(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

const unSub17 = async () =>{
  const docRef = doc(db, "ArejeDonation", 'reebosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setAmount5(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

const unSub18 = async () =>{
  const docRef = doc(db, "bukolaDonation", 'buubosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setAmount6(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

useEffect(() =>{
  unSub()
  unSub2()
  unSub3()
  unSub4()
  unSub5()
  unSub6()
  unSub7()
  unSub8()
  unSub9()
  unSub10()
  unSub11()
  unSub12()
  unSub13()
  unSub14()
  unSub15()
  unSub16()
  unSub17()
  unSub18()
  check()
}, )
  return (
    <div>
     <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Projects you’ve donated to <span className=' p-1 px-2 text-[12px] border border-gray-300 rounded-lg'>{a + b + c + e + f +d}</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {(kPrevDonation !== 0 || aPrevDonation !== 0 || ePrevDonation !== 0 || jPrevDonation !== 0 || rPrevDonation !== 0 || bPrevDonation !== 0 ) ?
          null :
            <p className=' text-center text-primary-color'>You have not donated to any projects yet </p>
          }
          
           {kPrevDonation > 0 && 
            <DonationCom
                img={One}
                name='Afolakemi Kehinde'
                sub='Rice processing'
                per={`${reducedValue1}%`}
                value={value1}
                price={`$${kPrevDonation}`}
                backers={backed1}
                weeks='12'
                link='/campaign'
            />
           }
            {aPrevDonation > 0 &&
              <DonationCom
                img={Sec}
                name='Abiodun Adewale'
                sub='Digital Printing & Framing'
                per={`${reducedValue2}%`}
                value={value2}
                price={`$${aPrevDonation}`}
                backers={backed2}
                weeks='12'
                link='/campaign2'
            />
            }
            {ePrevDonation > 0 &&
              <DonationCom
                img={third}
                name='Emmanuel Oguntade'
                sub='Electronics & Electrical Repairs and Sales'
                per={`${reducedValue3}%`}
                value={value3}
                price={`$${ePrevDonation}`}
                backers={backed3}
                weeks='12'
                link='/campaign3'
            />
            }
            {jPrevDonation > 0 &&
              <DonationCom
                img={fo}
                name='Joy Kounasso'
                sub=' Textile merchandizing'
                per={`${reducedValue4}%`}
                value={value4}
                price={`$${jPrevDonation}`}
                backers={backed4}
                weeks='12'
                link='/campaign4'
            />
            }
            {rPrevDonation > 0 &&
              <DonationCom
                img={fiv}
                name='Tubodun Areje'
                sub='3D Interior Wallframes'
                per={`${reducedValue5}%`}
                value={value5}
                price={`$${rPrevDonation}`}
                backers={backed5}
                weeks='12'
                link='/campaign5'
            />
            }
            {bPrevDonation > 0 &&
              <DonationCom
                img={six}
                name='Bukola Adedeji'
                sub='Manufacturing and Supply of ready to wear'
                per={`${reducedValue6}%`}
                value={value6}
                price={`$${bPrevDonation}`}
                backers={backed6}
                weeks='12'
                link='/campaign6'
            />
            }
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>All projects <span className=' p-1 px-2 text-[12px] border border-gray-300 rounded-lg'>6</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <DonationCom
                img={One}
                name='Afolakemi Kehinde'
                sub='Rice processing'
                per={`${reducedValue1}%`}
                value={value1}
                price={`$${kPrevDonation}`}
                backers={backed1}
                weeks='12'
                link='/campaign'
            />
            <DonationCom
                img={Sec}
                name='Abiodun Adewale'
                sub='Digital Printing & Framing'
                per={`${reducedValue2}%`}
                value={value2}
                price={`$${aPrevDonation}`}
                backers={backed2}
                weeks='12'
                link='/campaign2'
            />
             <DonationCom
                img={third}
                name='Emmanuel Oguntade'
                sub='Electronics & Electrical Repairs and Sales'
                per={`${reducedValue3}%`}
                value={value3}
                price={`$${ePrevDonation}`}
                backers={backed3}
                weeks='12'
                link='/campaign3'
            />
             <DonationCom
                img={fo}
                name='Joy Kounasso'
                sub=' Textile merchandizing'
                per={`${reducedValue4}%`}
                value={value4}
                price={`$${jPrevDonation}`}
                backers={backed4}
                weeks='12'
                link='/campaign4'
            />
             <DonationCom
                img={fiv}
                name='Tubodun Areje'
                sub='3D Interior Wallframes'
                per={`${reducedValue5}%`}
                value={value5}
                price={`$${rPrevDonation}`}
                backers={backed5}
                weeks='12'
                link='/campaign5'
            />
             <DonationCom
                img={six}
                name='Bukola Adedeji'
                sub='Manufacturing and Supply of ready to wear'
                per={`${reducedValue6}%`}
                value={value6}
                price={`$${bPrevDonation}`}
                backers={backed6}
                weeks='12'
                link='/campaign6'
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Fully funded <span className=' p-1 px-2 text-[12px] border border-gray-300 rounded-lg'>0</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* <FullyFundedCom
               img={third}
                name='Chuck Ogunde'
                sub='Gym owner'
                per='170%'
                price='$80'
                backers='3'
               
            />
             <FullyFundedCom
               img={last}
                name='Amina Usman'
                sub='Gym owner'
                per='70%'
                price='$80'
                backers='3'
               
            /> */}
            <p className=' text-center text-primary-color'>No fully funded campaign yet</p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <NavLink 
        className={({isActive}) =>{
        return( ' text-[#848484] block pl-6 p-2 ' + (
         !isActive ? ' hover:bg-[#F4F9F9] hover:border hover:rounded-[18px] ' : '  text-[#2D2B2B] font-bold text-[14px]'
         ))
          }} 
         to='/setting'>
          Edit profile
    </NavLink>
    <NavLink 
        className={({isActive}) =>{
        return( ' text-[#848484] block pl-6 p-2 ' + (
         !isActive ? ' hover:bg-[#F4F9F9] hover:border hover:rounded-[18px] ' : '  text-[#2D2B2B] font-bold text-[14px]'
         ))
          }} 
         to='/contact'>
         Contact Boosty
    </NavLink>
    <NavLink 
      onClick={Logout}
        className={({isActive}) =>{
        return( ' text-[#f44747] block pl-6 p-2 ' + (
         !isActive ? ' hover:bg-[#F4F9F9] hover:border hover:rounded-[18px] ' : '  text-[#2D2B2B] font-bold text-[14px]'
         ))
          }} 
         >
          Logout
    </NavLink>
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}