import React from 'react'
import Ab1 from '../../images/ab1.png'
import Ab2 from '../../images/ab2.png'
import Ab3 from '../../images/ab3.png'
import Button from '../SelectValue/Button'
import { Link } from 'react-router-dom'
function Empower() {
  return (
    <div className=' mb-[96px] grid sm:grid-cols-4' id='intro'>
    <div className=' sm:col-span-3 sm:pl-20'>
        <h1 className=' font-[700] text-[25px] text-[#2D2B2B] mb-[32px]'>Empowering Energy-Vulnerable Communities with Renewable Energy</h1>
        {/* <p className=' font-[600] text-[#3E3D3D] text-[16px] mb-[32px]'>Boosty is revolutionizing the way micro businesses in Africa access renewable energy. Through our crowdfunding platform, we enable individuals and organizations to easily contribute to the funding of renewable energy systems for these businesses. By doing so, we drive impactful change in developing countries' economies and the lives of their citizens.</p> */}
        <div className=' sm:flex mb-[48px]'>
            <img src={Ab1} alt='Empowered '/>
            <img src={Ab2} alt='Empowered '/>
            <img src={Ab3} alt='Empowered '/>
        </div>
        <div className=' mb-3'>
            <p className=' font-[600] text-[#3E3D3D] text-[16px]'>
            Boosty is changing how people in energy-vulnerable communities in Africa get renewable energy. Our crowdfunding platform makes it easy for anyone to help fund these vital energy systems. By doing this, we improve lives and boost local economies.
        <br></br> <br></br>
        Reliable and affordable power helps these communities thrive. It means better living conditions, more educational opportunities, improved healthcare, and stronger local businesses.
        <br></br> <br></br>
        Plus, by cutting down on fossil fuels, we help protect the environment. We keep you updated on how your donations are making a difference.
        <br></br> <br></br>
         Join us in making a real impact.
            </p>
        </div>
        <Link to='/project'>
        <Button
            name='Start giving'
        />
        </Link>
        
        </div>
    </div>
  )
}

export default Empower