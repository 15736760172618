import React from 'react'
import Navbar from '../../Components/Home/Navbar'
import Main from '../../Components/About/Main'
import Footer from '../../Components/Footer'
import Mobile from '../../Components/Mobile'

function Aboutus() {
  return (
    <div>
     <div className=' hidden lg:block'>
    <Navbar/>
    <div className=' pt-20'>
    <Main/>
    </div>
      <Footer/>
      </div>
      <div className=' lg:hidden block'>
        <Mobile/>
        </div>
    </div>
  )
}

export default Aboutus