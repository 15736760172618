import React from 'react'
import { Link } from 'react-router-dom'
 import Long from '../../images/longt.png'
import Button from '../SelectValue/Button'
function LongTerm() {
  return (
    <div className=' px-7 sm:mx-7 md:mx-0 py-[96px] grid md:grid-cols-2 gap-4'>
        <div className=' flex justify-center'>
            <div className=' wow animate__animated animate__fadeInLeft pt-5 lg:w-[60%]'>
               <h1 className=' text-[18px] md:text-[25px] font-[700] text-header-text mb-3'>Long-term change</h1> 
               <p className=' text-sub-text text-[16px]  font-[600]'>With our help, more people in developing countries will be able to adopt sustainable energy practices, increased economic opportunity, gain environmental literacy, and help lower the global CO2 emissions in the future. </p>
                <div className=' mt-6'>
                <Link to='/signupoptions'>
                    <Button
                        name='Start giving'
                    />
                    </Link>
                </div>
            </div>
        </div>
        <div className=' sm:flex sm:justify-end sm:flex-col'>
            <img src={Long} className='wow mx-auto animate__animated animate__zoomIn ' alt='Long'/>
        </div>
    </div>
  )
}

export default LongTerm