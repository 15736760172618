



import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import First from '../../images/avata.jpeg'
import Flash from '../../images/flash.png'
import Chart from '../../images/chart.png'
import CancelIcon from '@mui/icons-material/Cancel';
// import Send from '../../images/send.svg'
import { useUserAuth } from '../../Components/context/UserAuthContext';
import Textarea from '../SelectValue/Textarea';
import { addDoc, collection, } from 'firebase/firestore'
import { db } from '../../firebase-config'
import { toast } from 'react-toastify';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Success({open, handleClose, dimage, onClick, copy, email,link}) {
  const {user} = useUserAuth()
 const [show, setShow] = useState(false)
 const redirect = () =>{
  window.location.href = link
 }
 
    const [sub, setSub] = useState('')

  const sendmail = (e) =>{
    e.preventDefault()
    toast.success('Mail sent successfully!!')
   
    addDoc(collection(db, "mails"),{
      
        to: email,
        message: {
          subject: 'Message from funder',
          html: `<div> <p> Hello, ${email} just sent you a message. Below is the feedback.<br></br> ${sub} </p> </div>` ,
        },
      }
    )
    window.location.href = link
    setSub('')
  }
  return (
    <div>
      
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
      {!show ?
        <div className=' p-4'>
          
            <div className=' flex justify-end'>
                 <p onClick={redirect} className=' text-[18px] cursor-pointer'> <CancelIcon/></p>
            </div>

            <div className='flex  justify-center '>
        <div>
        <div className=' relative ml-9 mt-4 mx-auto mb-6 bg-[#272626] px-10 rounded-[18px] p-4 inline-block'>
        <div>
        <div className=' flex justify-center'>
        <div>
        <img src={ user.photoURL === null ? First :  `${user.photoURL}`} className=' rounded-full' alt='icon'/>
        </div>
          <div>
          <img src={dimage} width='90' height='90' alt='icon'/>
          </div>
           
        </div>
        <div className=' absolute inset-x-28 inset-y-8 '>
        <img src={Flash} alt='icon'/>
        </div>
        </div>
    </div>
    <div className=' mx-auto mt-10'>
            <h1 className='  md:text-[30px] text-header-text mb-3 font-bold'>Well done beautiful soul!</h1>
            <p className=' text-[18px] font-normal text-sub-text text-center'>You just powered someone’s project.</p>
    </div>
    <div className=' mt-5'> 
       
        <div className=' flex mb-3 cursor-pointer justify-center' onClick={() => setShow(true)}>
        <p className='  p-3 mb-3 bg-[#E8F2F2] inline-block text-[18px] rounded-[18px] font-[700] text-sub-text'> <img className=' inline-block px-3' src={Chart} alt='reply'/> Drop a message</p>
        </div>
        
    </div>
        </div>
        
    </div>
         
        </div>
        :
        <div className=' rounded-[15px] p-2'> 
        <div className=' flex justify-end'>
                 <p onClick={() => setShow(false)} className=' text-[18px] cursor-pointer'><CancelIcon/></p>
            </div>
            <div className='p-8'>
            <p>Send Feedback</p>
         <form onSubmit={sendmail} className=' '>
         <div className=' relative'>
         <Textarea 
            value={sub}
            onChange={setSub}
          />
            <button className=' absolute bottom-2 right-1'>
              {/* <img
                src={Send}
                alt="Send button"
              /> */}
            </button>
         </div>
         
         </form>
            </div>
           
        </div>
      }
      </Dialog>
    </div>
  );
}