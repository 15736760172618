import React from 'react'
 import Ms from '../../images/ms.png'
function Mx() {
  return (
    <div className=' py-[96px] rounded-b-2xl px-5 rounded-t-2xl md:mr-10 grid gap-6 sm:grid-cols-2 '>
        <div className=' sm:flex sm:justify-center'>
            <img src={Ms} alt='Microsoft bage'/>
        </div>
        <div className=' sm:flex sm:w-[60%] flex-col sm:justify-center'>
        <div>
        <p className=' text-[18px] sm:text-[25px] font-[700] text-[#2D2B2B]'>We are proud to anounce that Boosty is now part of Microsoft for Startups Founders Hub!</p>
        </div>
           
        </div>
    </div>
  )
}

export default Mx