import React from 'react'
import Navbar from '../../Components/Home/Navbar'
import Contacts from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer'
import Mobile from '../../Components/Mobile'

function Contact() {
  return (    
    <div>
     <div className=' hidden lg:block'>
    <Navbar/>
    <div className=' pt-[48px]'>
    <Contacts/>
    </div>
    <Footer/> 
    </div>
      
   <div className=' lg:hidden block'>
        <Mobile/>
        </div>
    </div>
  )
}

export default Contact