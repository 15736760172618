import React from 'react'

function Story() {
  return (
    <div className=' py-[96px] rounded-[16px] bg-[#F3F8F8]  grid p-3 px-10 sm:grid-cols-3' id='story'>
    <div className=' sm:col-span-2 sm:pl-8'>
        <h1 className=' font-[700] text-[25px] text-[#2D2B2B] mb-4'>Our story</h1>
        <p className='font-[600] text-[#3E3D3D] text-[16px]'>In 2019, during a mentorship session at the Founder Institute, Boosty founders Funmi and Aimua connected over shared frustrations with unreliable power and the difficulty of accessing renewable energy. Funmi's struggles to keep her Lagos-based ventures running on the national power grid and costly generators struck a chord with Aimua, who faced similar issues in his freelance design work and coconut flakes business.
<br></br><br></br>
Motivated by these challenges, they set out to find a solution that would provide reliable, affordable renewable energy to people living in energy-vulnerable communities. Over four years, through a global pandemic and many changes, Funmi and Aimua co-founded Boosty with the goal of transforming energy access for communities across Africa.
<br></br><br></br>
With determination and extensive research, they developed a deep understanding of the hurdles these communities face and the incredible potential of renewable energy. Despite the distance between them—Funmi in Nigeria and Aimua in Canada—they worked together to build a network of supporters, aiming to empower communities in need.
<br></br><br></br>
Funmi and Aimua are driven by a passion for positive change, dedicated to making renewable energy accessible for all and fostering sustainable growth and prosperity.</p>
    </div>
    </div>
  )
}

export default Story