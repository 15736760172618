import React from 'react'
import LinkedIn from '../../images/default.svg'
function TeamCom({img, name, link, title, con}) {
  return (
    <div className='bg-[#F3F8F8] w-full p-4 rounded-[16px] grid sm:grid-cols-2 gap-4 md:grid-cols-5'>
    <div className=' sm:col-span-2'>
        <div className=' mb-3'>
            <img src={img} alt={name}/>
        </div>
        <p className=' text-[16px] font-[700] text-[#2D2B2B] mb-3'>{name}</p>
        <p className=' text-[14px] font-[600] text-[#878787] mb-3'>{title}</p>
        <a href={link} target='_blank' rel='noreferrer'>
        <img src={LinkedIn} alt='LinkedIn'/>
        </a>
       
    </div>
    <div className=' sm:col-span-3'>
        <p className=' text-[#3E3D3D] text-[14px] font-[600]'>{con}</p>
    </div>
    </div>
  )
}

export default TeamCom