import React from 'react'
import Logo from '../../images/boostylogo.png'
// import Imm from '../../images/tt.png'
import Reply from '../../images/reply.svg'
import Back from '../../images/bb.svg'
import { Link } from 'react-router-dom'
function PaymentSuccess() {
  return (
    <div className=' mt-6  flex justify-between'>
    <div>

    </div>
    <div className=' mx-auto'>
    <div>
            <img className=' mx-auto my-10' src={Logo} alt='Logo'/>
        </div> 
        <div className=' mb-5'>
        {/* <img className=' mx-auto my-10' src={Imm} alt='ss'/> */}
        </div>
        <div className=' mx-auto mt-10'>
            <h1 className='  md:text-[30px] text-header-text mb-3 font-bold'>Well done beautiful soul!</h1>
            <p className=' text-[18px] font-normal text-sub-text text-center'>You just powered someone’s project.</p>
    </div>
    <div className=' mt-5'> 
        <div className=' flex mb-3 justify-center cursor-pointer' >
        <p className='  p-3 mb-3 bg-[#E8F2F2] cursor-pointer inline-block text-[18px] rounded-[18px] font-[700] text-sub-text'> <img className=' inline-block px-3' src={Reply} alt='reply'/> Share with friends</p>
        </div>
       
        
        
    </div>
    </div>
    <div className=' flex flex-col justify-center px-10'>
    <Link to='/signup'><img src={Back} alt='Back'/>  </Link>  
    </div>
    </div>
  )
}

export default PaymentSuccess