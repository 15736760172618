import React from 'react'
import CreditCardPayment from '../../Components/Payment/CreditCardPayment'

function CreditCard() {
  return (
    <div>
        <CreditCardPayment/>
    </div>
  )
}

export default CreditCard