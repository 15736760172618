import React, { useEffect, useState,  } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { createRecord } from '../context/DbContext';
import { useUserAuth } from '../context/UserAuthContext';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loadingdropdown';
function About() {
    const { user } = useUserAuth();
    const [aboutMe, setAboutMe] = useState('')
    const [loading, setLoading] = useState(false)
    const uuid = user.uid
    const handleSubmit = async (e) =>  {
            e.preventDefault()
            const data = {
                about: aboutMe,
              };
              setLoading(true)
        const response=   await createRecord(uuid,'about',data)
        setLoading(false)
        toast("Details about you added. Thanks");
        console.log(response)
    }


const unSub = async () =>{
    const docRef = doc(db, "about", uuid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data().about);
      setAboutMe(docSnap.data().about)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
}
    
    useEffect(() =>{
        unSub()
    }, [])
    
  return (
    <div>
     <ToastContainer />
     <Loading open={loading}/>
        <form onSubmit={handleSubmit}>
            <div>
                <textarea
                    className=' p-4 rounded-[18px] w-full border-gray-300 focus:outline-0 border'
                    value={aboutMe}
                    required
                    onChange={e => setAboutMe(e.target.value)}

                />
            </div>
            <div className=' mt-3'>
                <button className=' p-1 w-full text-start  rounded-[18px] border-gray-300 border '>
                    <AddIcon className='text-[#848484]'/> Add a your story
                </button>
            </div>
        </form>
    </div>
  )
}

export default About