import React from 'react'
import OptionComponent from './OptionComponent'
import Individual from '../../images/individual.png'
import Company from '../../images/organisation.png'
function Options() {
  return (
    <div className=' mt-10 flex justify-center p-4'>
        <div>
            <h1 className=' text-header-text font-[700] text-[18px] text-center md:text-[30px] mb-7'>Select how you wish to donate</h1>
            <OptionComponent
                name='As organizations'
                sub='Make donations as an organization and track employee donations as well.'
                img={Company}
                link='/signup'
            />
            <OptionComponent
                name='As individuals'
                sub='Make donations solo and invite friends to do the same.'
                img={Individual}
                link='/signup'
            />
        </div>
    </div>
  )
}

export default Options