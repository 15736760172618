import React, { useEffect, useState,  } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { createRecord } from '../context/DbContext';
import { useUserAuth } from '../context/UserAuthContext';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Loading from '../SelectValue/Loadingdropdown';
function Job() {
    const { user } = useUserAuth();
    const [jobTitle, setJobTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const uuid = user.uid
    const handleSubmit = async (e) =>  {
            e.preventDefault()
            const data = {
                job: jobTitle,
              };
              // const datas = {
              //   donation: 320.51,
              // };
              setLoading(true)
        const response=   await createRecord(uuid,'jobs',data)
        // const responses=   await createRecord('buubosty','bukolaDonation',datas)
        setLoading(false)
        toast("Job added. Thanks");
        console.log(response)
    }
    //  onSnapshot(doc(db, "jobs", uuid), (doc) => {
    //     console.log("Current data: ", doc.data().job);
    //     setJobTitle(doc.data().job)
    // });
    
    const unSub = async () =>{
        const docRef = doc(db, "jobs", uuid);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().job);
          setJobTitle(docSnap.data().job)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
    }
        
        useEffect(() =>{
            unSub()
        }, [])
    
    
  return (
    <div>
    <ToastContainer />
    <Loading open={loading}/>
        <form onSubmit={handleSubmit} className=' w-full'>
            <div className=' w-full'>
                <input
                    className=' p-4 rounded-[18px] outline-0 w-full border-gray-300 border'
                    value={jobTitle}
                    required
                    onChange={e => setJobTitle(e.target.value)}

                />
            </div>
            <div className=' mt-3'>
                <button className=' p-1 w-full text-start  rounded-[18px] border-gray-300 border '>
                    <AddIcon className='text-[#848484]'/> Add a job
                </button>
            </div>
        </form>
    </div>
  )
}

export default Job