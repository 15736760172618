import React from 'react'
import Navback from '../../Components/NavBack'
import SignupForm from '../../Components/Signup/SignupForm'

function Signup() {
  return (
    <div>
        <Navback/>
        <SignupForm/>
        
    </div>
  )
}

export default Signup