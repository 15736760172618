import React, { useEffect, useState } from 'react'
import ProjectCom from './ProjectCom'
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
function Project() {
    const [amount1, setAmount1] = useState(0)
const [backed1,setBacked1] = useState(0)
const [amount2, setAmount2] = useState(25.64)
  const [backed2,setBacked2] = useState(0)
  const [amount3, setAmount3] = useState(25.64)
const [backed3,setBacked3] = useState(0)
const [amount4, setAmount4] = useState(0)
const [backed4,setBacked4] = useState(0)
const[backed5, setBacked5] = useState(0)
 const[backed6, setBacked6] = useState(0)
 const [amount5, setAmount5] = useState(0)
 const [amount6, setAmount6] = useState(0)
const unSub1 = async () =>{
    const docRef = doc(db, "kindeDonation", 'kkkbosty');
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data().donation);
      setAmount1(docSnap.data().donation)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const unSub2 = async () =>{
    const docRef = doc(db, "kindeDonation", 'kkkbacked');
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data().backed);
      setBacked1(docSnap.data().backed)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }
  
  const unSub3 = async () =>{
      const docRef = doc(db, "abiodunDonation", 'aaabosty');
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().donation);
        setAmount2(docSnap.data().donation)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  
    const unSub4 = async () =>{
      const docRef = doc(db, "abiodunDonation", 'aaabacked');
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().backed);
        setBacked2(docSnap.data().backed)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }
    const unSub5 = async () =>{
        const docRef = doc(db, "emmaDonation", 'eeebosty');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().donation);
          setAmount3(docSnap.data().donation)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    
      const unSub6 = async () =>{
        const docRef = doc(db, "emmaDonation", 'eeebacked');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().backed);
          setBacked3(docSnap.data().backed)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
      const unSub7 = async () =>{
        const docRef = doc(db, "joyDonation", 'jjjbosty');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().donation);
          setAmount4(docSnap.data().donation)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    
      const unSub8 = async () =>{
        const docRef = doc(db, "joyDonation", 'jjjbacked');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().backed);
          setBacked4(docSnap.data().backed)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
      const unSub9 = async () =>{
        const docRef = doc(db, "ArejeDonation", 'reebosty');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().donation);
          setAmount5(docSnap.data().donation)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    
      const unSub10 = async () =>{
        const docRef = doc(db, "ArejeDonation", 'reebacked');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().backed);
          setBacked5(docSnap.data().backed)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
      const unSub11 = async () =>{
        const docRef = doc(db, "bukolaDonation", 'buubosty');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().donation);
          setAmount6(docSnap.data().donation)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    
      const unSub12 = async () =>{
        const docRef = doc(db, "bukolaDonation", 'buubacked');
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data().backed);
          setBacked6(docSnap.data().backed)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    useEffect(() =>{
        unSub1()
        unSub2()
        unSub3()
        unSub4()
        unSub5()
        unSub6()
        unSub7()
        unSub8()
        unSub9()
        unSub10()
        unSub11()
        unSub12()
    }, [])
const value1= (amount1/ 15941.25) * 100
const value2 = (amount2/ 11019.81) * 100
const value3 = (amount3/ 5201.83) * 100
const value4 = (amount4/ 16729.23) * 100
const value5 = (amount5/ 4747.50) * 100
const value6 = (amount6/ 7561.03) * 100
  return (
    
    <div className=' px-5 sm:px-0 md:w-[75%]  mx-auto'>
        <h1 className=' font-[700] text-[25px]  mb-4'>Select a project to give to </h1>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value1}
            amount={amount1}
            backed={backed1}
            count={6}
            name='Afolakemi Kehinde'
            work='Training, planting, processing, packaging and marketing unpolished rice'
            video='Mjo5r7h1FKM'
            header='Give my rice processing company a boost'
            cont='I will use renewable energy to power my grinding machine, rice de-stoner machine, rice blower, rice dryer, rice sack sewing machine, electric package sealant, electric digital scale, and rice miller. Your donations matter!'
            total='15,941.25'
            raised='32.10'
            location='Ogun, Nigeria'
            co='21,216 Kg '
            saved='$4573.8 per Year'
           link='/donate1/1'
           linkA='/donate1/32'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value2}
            amount={amount2}
            backed={backed2}
            name='Abiodun Adewale'
            work='Digital Printing & Framing'
            video='e8llCuq1qQU'
            header='Give my Printing Company a boost'
            cont=' I will use a renewable energy system to power my SMT heat soldering machines, soldering irons, office lighting, and monitors. Your donation counts!'
            total='11,019.81'
            raised='25.64'
            location='Lagos, Nigeria'
            link='/donate2/1'
            linkA='/donate1/26'
            co='6,520 Kg '
            saved='$2311.7 per Year'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value3}
            amount={amount3}
            backed={backed3}
            name='Emmanuel Oguntade '
            work='Electronics & Electrical Repairs and Sales'
            video='BELmh-LSFCw'
            header='Give my electronic repair shop a boost'
            cont=' I will use a renewable energy system to power my SMT heat soldering machines, soldering irons, office lighting, and monitors. Your donation counts!'
            total='5,201.83'
            raised='25.64'
            location='Oyo, Nigeria'
            link='/donate3/1'
            linkA='/donate1/26'
            co='12,916.8 Kg '
            saved='$4225.7 per Year'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value4}
            amount={amount4}
            backed={backed4}
            name='Joy  Kounasso'
            work='A textile Merchandising bespoke'
            video='BcHhg-vhcGY'
            header='Boost my textile merch  business.'
            cont='I will use a renewable energy system to power my industrial straight sewing machine, monogram machine, presser, plotter tacker, buttonhole machine, cutter phonic machine, and elasticated machine. Every dollar counts!'
            total='6,729.23'
            raised='25.6'
            location='Lagos, Nigeria'
            link='/donate4/1'
            linkA='/donate1/26'
            co='14,352 Kg '
            saved='$4695.2 per Year'
        />
        </div>
        <div className=' mb-[96px]'>
        <ProjectCom
            value={value5}
            amount={amount5}
            backed={backed5}
            name='Tunbosun Areje '
            work='3D Interior Wall frame Designer'
            video='WlacAELKshg'
            header='Boost my wall frame design business '
            cont='I will use a renewable energy system to power my drilling machine, sandpaper machine, jigsaw, and television. Your donation counts!'
            total='4,747.50'
            raised='72.92'
            location='Ondo, Nigeria'
            link='/donate5/1'
            linkA='/donate1/73'
            co='7,893.6 Kg '
            saved='$2582.3 per Year'
        />
        </div>
        <div className=' mb-[120px]'>
        <ProjectCom
            value={value6}
            amount={amount6}
            backed={backed6}
            name='Bukola Adedeji'
            work=' Manufacturing and Supply of ready to wear.'
            video='kfha6b1f6Bw'
            header='Give my ready-to-wear fabric business a boost'
            cont=' I will use a renewable energy system to power my monogram 6 heads, presser, cutter, industrial machines, taping and buttonhole machine, and tacker machine. I am counting on you!'
            total='7,561.03'
            raised='320.51'
            location='Lagos, Nigeria'
            link='/donate6/1'
            linkA='/donate1/321'
            co='10,764 Kg '
            saved='$3521.4 per Year'
        />
        </div>
       
    </div>
  )
}

export default Project