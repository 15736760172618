import React, {useRef, useState } from 'react'
import Mo from '../images/mobile.svg'
import Send from '../images/sendd.svg'
import { addDoc, collection, } from 'firebase/firestore'
import { db } from '../firebase-config'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import Loading from './SelectValue/Loadingdropdown'
function Mobile() {
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(false)

  // const sendmail = (e) =>{
  //   e.preventDefault()
  //   toast.success('Mail sent successfully!!')
  //   addDoc(collection(db, "mails"),{
      
  //       to: email,
  //       message: {
  //         subject: 'Boosty tech.!',
  //         html: `<div> <p> Hello, here is the link <a href='https://boostytech.com/'>Boosty website</a> to access boosty on your web </p> </div>` ,
  //       },
  //     }
  //   )
  
  // }
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setOpen(true)
    emailjs.sendForm('service_c31sijs', 'template_o16m8u9', form.current, 'TADsQHIzOyPE8sq_t')
      .then((result) => {
        setOpen(false)
          console.log(result.text);
          toast("Message sent sucessfully")
          e.target.reset()
      }, (error) => {
        setOpen(false)
          console.log(error.text);
      });
  };
  return (
    <div className='bg-[#F3F8F8] grid h-screen  p-4 px-6 m-4'>
    <ToastContainer/>
    <Loading
      open={open}
    />
      <div className=' mx-auto'>

        <div className=' my-10 pt-4'>
            <img src={Mo} className=' ' alt='mobile'/>
        </div>
        <h1 className=' text-[28px] text-[#3E3D3D]   font-[700] mb-3'>Boosty is much better on the big screen!</h1>
        <p className=' text-[16px] text-[#3E3D3D]  font-[600] mb-3'>Switch to desktop or laptop for an enhanced and empowering journey. 💪
        <br></br><br></br><br></br>
        Please email our website link to yourself for later viewing on a larger screen.</p>
        <form onSubmit={sendEmail} ref={form}>
        <div className='flex my-4'>
          <input name="user_email" type='email' required className=' p-2 bg-white rounded-l-md border border-gray-200 ' placeholder=' Type your email here'/> 
          <button><img src={Send} alt='send'/></button>
        </div>
        </form>
        <p className=' text-[16px] text-[#3E3D3D]  font-[600] mb-3'>See you there!</p>
    </div>
    </div>
  )
}

export default Mobile