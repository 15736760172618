import React, { useEffect, useState } from 'react'
import Back from '../Back'
import { PayPalButton } from "react-paypal-button-v2";
import Pi from '../../images/c44.png'
import { createRecord } from '../context/DbContext';
import Logo from '../../images/boostylogo.png'

import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase-config';
import { useNavigate, useParams } from 'react-router-dom';
function Payment6() {
  const{amount} = useParams()
  let amountInt = parseInt(amount)
  const [amountt, setAmount] = useState(amountInt)
  const [prevDonation, setPrevDonation] = useState(0)
  const [kPrevDonation, setKPrevDonation] = useState(0)
  const navigate = useNavigate()
  //const { user } = useUserAuth();
  const [donationValues, setDonationValue] = useState(0)
  const [backed, setBacked] = useState(0)
  
  const percentage = 2.99;
  // Calculate the result
  const result = (percentage / 100) * amountt;
  //const uuid = user.uid
  const increament = () =>{
      setAmount(prevState => prevState + 10)
}
const decreament = () =>{
  if(amountt >= 1){ 
      setAmount(amountt - 10)
  }
  else{
      setAmount(amountt)
  }
 
}
const donationValue = async () =>{
  // const data = {
  //   donation: amountt,
  // };
  const dataa = {
    donation: parseInt(amountt) +  parseInt(prevDonation),
  };
  const dattaa = {
    donation: parseInt(amountt) +  parseInt(kPrevDonation),
  };
  const datas = {
    donation: parseInt(donationValues) + parseInt(amountt),
  };
  const datass = {
    backed: parseInt(backed) + 1,
  };
//const response=   await createRecord(uuid,'donations',dataa)
const responses = await createRecord('buubosty','bukolaDonation',datas)
const responsess = await createRecord('buubacked','bukolaDonation',datass)
//const responsesss=   await createRecord(uuid,'udonations',dattaa)

//console.log(response)
console.log(responses)
console.log(responsess)
//console.log(responsesss)
}

const handleInputChange = (event) => {
  const inputValue = parseInt(event.target.value);
  if (!isNaN(inputValue)) {
    setAmount(inputValue);
  }else{
    setAmount(0)
  }
};
let semiFinal = Math.ceil(amountt + result)
let finalValue = (semiFinal).toString()
console.log(finalValue + 'dddd')
const unSub = async () =>{
  const docRef = doc(db, "bukolaDonation", 'buubosty');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().donation);
    setDonationValue(docSnap.data().donation)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
const unSub1 = async () =>{
  const docRef = doc(db, "bukolaDonation", 'buubacked');
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data().backed);
    setBacked(docSnap.data().backed)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}
// const unSub2 = async () =>{
//   const docRef = doc(db,  'donations',uuid);
//   const docSnap = await getDoc(docRef);

//   if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data().donation);
//     setPrevDonation(docSnap.data().donation)
//   } else {
//     // docSnap.data() will be undefined in this case
//     console.log("No such document!");
//   }
// }
// const unSub3 = async () =>{
//   const docRef = doc(db,  'udonations',uuid);
//   const docSnap = await getDoc(docRef);

//   if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data().donation);
//     setKPrevDonation(docSnap.data().donation)
//   } else {
//     // docSnap.data() will be undefined in this case
//     console.log("No such document!");
//   }
// }



//getAuthorization()


//sendMail()
const sendmail = () =>{
  addDoc(collection(db, "mails"),{
    
      to: ['unchanginggrace2020@gmail.com'],
      message: {
        subject: 'Donation confirmation message',
        html: `<div> <p>Hi Bukola, <br><br> Your renewable energy project has received a donation of $${amountt}. <br><br> You have $${parseInt(amountt) +  parseInt(prevDonation)} donated so far</p> </div>` ,
      },
    }
  )

}
const sendmail1 = () =>{
  addDoc(collection(db, "mails"),{
    
      to: ['info@boostytech.com','aithelight@gmail.com'],
      message: {
        subject: 'Donation confirmation message',
        html: `<div> <p>Hi Boosty, <br><br> Your Bukola renewable energy project has received a donation of $${amountt}. <br><br> You have $${parseInt(amountt) +  parseInt(prevDonation)} donated so far</p> </div>` ,
      },
    }
  )

}
  useEffect(() =>{
      unSub()
       unSub1()
      //  unSub2()
      //  unSub3()
  }, [])
  return (
    <div className=' mx-auto w-[70%]'> 
    {/* <Success
    onClick={handleCopyClick}
    copy={copied}
      open={open}
      handleClose={handleClose}
      dimage={Pi}
      link='/campaign'
    /> */}
    <div>
     <div>
            <img className=' mx-auto mb-3 mt-10' src={Logo} alt='Logo'/>
        </div> 
         <div className=' mx-auto  grid grid-cols-6'>
    <div className=' flex flex-col px-10  pb-5 md:pb-0 justify-items-center md:mt-32'>
        <Back/>
    </div>
       
        <div className=' col-span-4'>
         <div className=' rounded-[18px] mb-8 border grid lg:grid-cols-2 gap-6 px-6 py-8 border-[#C4C4C4]'>
        <div>
            <h1 className=' md:text-[30px] mb-4 font-bold text-header-text'>Back this project </h1>
            <div className=' flex gap-5'>
                <div>
                    <img src={Pi} alt='img'/>
                </div>
                <div>
                    <h1 className=' md:text-[18px] mb-2 font-bold text-header-text'>Give my ready-to-wear fabric business a boost</h1>
                    <p className='text-[12px] font-normal text-sub-text'>I will use a renewable energy system to power my monogram 6 heads, presser, cutter, industrial machines, taping and buttonhole machine, and tacker machine. I am counting on you!</p>
                </div>
            </div>
        </div>
        <div className=' flex flex-col justify-center'>
            <div className=' mx-auto'>
                <h1 className=' md:text-[18px] mb-4 font-bold   text-header-text'>Donation amount</h1>
                <div className=' flex gap-6'>
                {/* <div className='mt-3 flex '>
                    <div  onClick={decreament} className=' cursor-pointer p-3 font-bold border-r border-r-[#ACABAB] rounded-tl-[18px] rounded-bl-[18px] bg-[#E8F2F2]'>
                        -
                    </div>
                    <div onClick={increament} className=' cursor-pointer p-3 font-bold  rounded-tr-[18px] rounded-br-[18px] bg-[#E8F2F2]'>
                        +
                    </div>
                </div> */}
                <div className=' flex '>
              
              <div className='dollar-input'>
              <input
                   className=' mt-3 p-3 appearance-none bg-[#E8F2F2] pl-6 border rounded-l-[12px] border-[#C3C5C5]  w-full outline-0 '
                   type='text'
                   required
                   value={amountt}
                   onChange={handleInputChange}
                  />
              </div>
                
                    <span className="mt-3 p-3 bg-[#E8F2F2] border border-[#C3C5C5] rounded-r-[12px]">USD</span>
              </div>
                </div>
               
            </div>
        </div>
    </div>
    <div className=' block mb-3'>
    <p className='md:text-[18px] mb-4 font-bold   text-header-text'><span className=' font-light'>Transaction fee:</span> ${Math.ceil(result)} USD will be added to your donation amount  </p>
    </div>
    <div className=' rounded-[18px] mb-8 border flex justify-between gap-6 px-6 py-8 border-[#C4C4C4]'>
    
    <div>
        <p>Estimated total: ${Math.ceil(result + amountt)}</p>
    </div>
        <PayPalButton
        amount={finalValue}
        // shippingPreference="Kehinde campaign donation" // default is "GET_FROM_FILE"
        onSuccess={ (details, data)  =>  {
          alert("Transaction completed by " + details.payer.name.given_name);
          console.log(details)
          console.log(data)
          navigate('/sucess')
          donationValue()
          sendmail()
          sendmail1()
          // OPTIONAL: Call your server to save the transaction
          return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({
              orderID: data.orderID
            })
          });
        }}
        options={{
        clientId: "AWshQ3PVbwl8jGkKk_wjXlwwl18r3lkowE7l-fSWKyEc_g5IcD8w2h1rY_6fsbs72WlAX_gm892X3aR_",
        currency: "USD"
    }}
      />
      <div></div>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Payment6