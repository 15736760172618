import React from 'react'

function GiveButton({onClick, name, bool}) {
  return (
    <div> <button 
    className={ bool ? 'px-[48px] py-[8px]   rounded-[18px] text-[#fff] bg-button-color ': 'px-[48px] py-[8px]   rounded-[18px] text-[#98957A] bg-primary-color2 '}
    onClick={onClick}
    
   >{name} </button>   </div>
  )
}

export default GiveButton